import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/subscriber-configuration/yearly-calendar`;

// To Add Yearly Calendar Event
export const addYearlyCalendarEvent = (token, subscriberId, event) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.post(`${backEndUrl}/add-yearly-calendar-event/${subscriberId}`, event, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Yearly Calendar Event
export const getYearlyCalendarEvent = (token, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.get(`${backEndUrl}/get-yearly-calendar-event/${subscriberId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To update Yearly Calendar Event
export const updateYearlyCalendarEvent = (token, subscriberId, event) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.put(`${backEndUrl}/update-yearly-calendar-event/${subscriberId}/${event?.eventId}`, event, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To update Yearly Calendar Event
export const deleteYearlyCalendarEvent = (token, subscriberId, eventId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.delete(`${backEndUrl}/delete-yearly-calendar-event/${subscriberId}/${eventId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};