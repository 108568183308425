import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from '@mui/icons-material/Delete';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { getuserDetailsByTabName, updateUser } from '../../../../utils/Services/userServices'

function FamilyDetails() {
    const [familyInformationDetails, setFamilyInformationDetails] = useState();
    const [rows, setRows] = useState([]);
    const [numRows, setNumRows] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { userId } = useParams();
    const tabName = "familyDetails"

    // To get user family details
    const fetchFamilyDetails = async () => {
        setLoading(true);
        try {
            const response = await getuserDetailsByTabName(token, tabName, userId);
            setFamilyInformationDetails(response?.data?.familyInformation)
        } catch (err) {
            console.error('Error fetching user family details:', err);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to fetch user family details',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchFamilyDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (familyInformationDetails?.children) {
            setRows(familyInformationDetails.children);
            setNumRows(familyInformationDetails.noOfChildren || '');
        }
    }, [familyInformationDetails]);

    // Handle to edit the fields
    const handleEditClick = async () => {
        if (isEditing) {
            await fetchFamilyDetails();
        }
        setIsEditing(prevEditing => {
            const newEditingState = !prevEditing;
            if (newEditingState) {
                Swal.fire({
                    icon: 'info',
                    title: 'Editing Enabled',
                    text: 'Now you can edit the fields.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
            return newEditingState;
        });
    };

    // To Add rows for children
    const handleAddRows = () => {
        const newChildren = familyInformationDetails?.children ? [...familyInformationDetails.children, { childName: '', childDOB: '' }] : [{ childName: '', childDOB: '' }];
        setFamilyInformationDetails({
            ...familyInformationDetails,
            noOfChildren: newChildren.length,
            children: newChildren
        });
        setRows(newChildren);
        setNumRows(newChildren.length);
    };

    // To Rows rows for children
    const handleRemoveRow = (index) => {
        const updatedChildren = familyInformationDetails?.children ? familyInformationDetails.children.filter((_, i) => i !== index) : [];
        setFamilyInformationDetails({
            ...familyInformationDetails,
            noOfChildren: updatedChildren.length,
            children: updatedChildren
        });
        setRows(updatedChildren);
        setNumRows(updatedChildren.length);
    };

    // Handle input change for children
    const handleInputChange = (index, field, value) => {
        const updatedChildren = familyInformationDetails?.children ? [...familyInformationDetails.children] : [];
        updatedChildren[index][field] = value;
        setFamilyInformationDetails({
            ...familyInformationDetails,
            children: updatedChildren
        });
        setRows(updatedChildren);
    };

    // Format Date
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0];
    };

    // Hanlde Update
    const handleUpdate = async () => {
        setLoading(true);
        try {
            const updatedData = { familyInformationDetails }
            await updateUser(token, userId, tabName, updatedData);
            Swal.fire({
                icon: 'success',
                title: 'Family Details Updated',
                text: 'The family details have been successfully updated.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'There was an error updating the family details. Please try again.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            console.error('Error updating family details:', err);
        } finally {
            setLoading(false);
            setIsEditing(false);
            fetchFamilyDetails();
        }
    };

    return (
        <span>
            <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
                <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
                    <Grid item>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#5A9BD5',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#5A9BD5',
                                    opacity: 1
                                }
                            }}
                            endIcon={<EditIcon />}
                            onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                        </Button>
                    </Grid>
                    <Grid item>
                        {isEditing && (
                            <Button variant="outlined"
                                sx={{
                                    backgroundColor: '#1B4965',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#1B4965',
                                        opacity: 1
                                    }
                                }}
                                endIcon={<RestartAltIcon />}
                                onClick={() => {
                                    if (isEditing) {
                                        handleUpdate();
                                    }
                                }}
                            > Update
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <TextField id="fatherName" name="fatherName" label="Father Name" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, fatherName: e.target.value })} value={familyInformationDetails?.fatherName || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="fatherDOB" label="Father Date Of Birth" type="date" name="fatherDOB" value={familyInformationDetails?.fatherDOB ? familyInformationDetails.fatherDOB.split('T')[0] : ''} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, fatherDOB: e.target.value })} InputLabelProps={{ shrink: true }} disabled={!isEditing} fullWidth margin="dense" variant="outlined" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' } }} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="fatherOccupation" name="fatherOccupation" label="Father Occupation" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, fatherOccupation: e.target.value })} value={familyInformationDetails?.fatherOccupation || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <TextField id="motherName" name="motherName" label="Mother Name" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, motherName: e.target.value })} value={familyInformationDetails?.motherName || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="motherDOB" label="Mother Date Of Birth" type="date" name="motherDOB" value={familyInformationDetails?.motherDOB ? familyInformationDetails.motherDOB.split('T')[0] : ''} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, motherDOB: e.target.value })} InputLabelProps={{ shrink: true }} disabled={!isEditing} fullWidth margin="dense" variant="outlined" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' } }} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="motherOccupation" name="motherOccupation" label="Mother Occupation" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, motherOccupation: e.target.value })} value={familyInformationDetails?.motherOccupation || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <FormControl variant='outlined' required fullWidth margin='dense'>
                            <InputLabel id="maritalStatus">Marital Status</InputLabel>
                            <Select labelId='maritalStatus' id='maritalStatus' label="Marital Status" disabled={!isEditing} value={familyInformationDetails?.maritalStatus || ''} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, maritalStatus: e.target.value })} margin="dense" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                                <MenuItem value="married">Married</MenuItem>
                                <MenuItem value="unmarried">Un-Married</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="spouseName" name="spouseName" label="Spouse Name" variant="outlined" fullWidth margin="dense" disabled={!isEditing || familyInformationDetails?.maritalStatus !== 'married'} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, spouseName: e.target.value })} value={familyInformationDetails?.spouseName || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="spouseDOB" label="Spouse Date Of Birth" type="date" name="spouseDOB" value={familyInformationDetails?.spouseDOB ? familyInformationDetails.spouseDOB.split('T')[0] : ''} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, spouseDOB: e.target.value })} InputLabelProps={{ shrink: true }} disabled={!isEditing || familyInformationDetails?.maritalStatus !== 'married'} fullWidth margin="dense" variant="outlined" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' } }} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="spouseOccupation" name="spouseOccupation" label="Spouse Occupation" variant="outlined" fullWidth margin="dense" disabled={!isEditing || familyInformationDetails?.maritalStatus !== 'married'} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, spouseOccupation: e.target.value })} value={familyInformationDetails?.spouseOccupation || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <TextField id="dependentName" name="dependentName" label="Dependent Name" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, dependentName: e.target.value })} value={familyInformationDetails?.dependentName || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="dependentRelation" name="dependentRelation" label="Dependent Relation" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, dependentRelation: e.target.value })} value={familyInformationDetails?.dependentRelation || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="dependentDOB" label="Dependent Date Of Birth" type="date" name="dependentDOB" value={familyInformationDetails?.dependentDOB ? familyInformationDetails.dependentDOB.split('T')[0] : ''} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, dependentDOB: e.target.value })} InputLabelProps={{ shrink: true }} disabled={!isEditing} fullWidth margin="dense" variant="outlined" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' } }} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="dependentOccupation" name="dependentOccupation" label="Dependent Occupation" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setFamilyInformationDetails({ ...familyInformationDetails, dependentOccupation: e.target.value })} value={familyInformationDetails?.dependentOccupation || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <TextField id="noOfChildren" name="noOfChildren" label="No of Children" variant="outlined" fullWidth margin="dense" disabled={true} value={numRows} onChange={(e) => setNumRows(e.target.value)}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {isEditing && (
                            <Button variant="contained"
                                sx={{
                                    backgroundColor: '#51b86c',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#51b86c',
                                        opacity: 1,
                                    },
                                }}
                                onClick={handleAddRows}
                            >Add Rows
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {rows.map((row, index) => (
                    <Grid container spacing={2} alignItems="center" key={index}>
                        <Grid item xs={4}>
                            <TextField name={`childName-${index}`} label="Child Name" variant="outlined" fullWidth margin="dense" disabled={!isEditing} value={row.childName} onChange={(e) => handleInputChange(index, 'childName', e.target.value)}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%',
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField name={`childDOB-${index}`} label="Child Date Of Birth" type="date" variant="outlined" fullWidth margin="dense"
                                disabled={!isEditing}
                                value={formatDate(row.childDOB)}
                                onChange={(e) => handleInputChange(index, 'childDOB', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {isEditing && (
                                <IconButton onClick={() => handleRemoveRow(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Container>
            {(loading) && (
                <Loader />
            )}
        </span>
    )
}

export default FamilyDetails

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;