import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { ListItemIcon, MenuItem, Box } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
// import { AccountCircle, Send } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import './Userslist.css'
import userImg from '../../../assets/Mixed/profile.png'
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { UserType, S3FileType } from '../../../types'
import { UserStatus, statusColors } from '../../../types/statusTypes';
import Pagination from '../../../Components/Pagination/Pagination';
import { selectToken } from '../../../Redux/selector';
import UserRegistration from '../../../Components/Registrations/UserRegistration/UserRegistration';
import { getAllUsersDetails, suspendUserById, deleteUserById } from '../../../utils/Services/userServices'

const Userslist = () => {
  const [data, setData] = useState<UserType[]>([]);
  const [openModal, setUserRegistrationModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [loading, setLoading] = useState<boolean>(false);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();
  const navigate = useNavigate();

  // To fetch Users Details
  const fetchUsersDetail = async () => {
    setLoading(true);
    try {
      const response = await getAllUsersDetails(token, subscriberId);
      const sortedData = response?.data?.data?.sort((a: { officeInformation: { employeeCode: string; }; }, b: { officeInformation: { employeeCode: any; }; }) => {
        if (a?.officeInformation?.employeeCode && b?.officeInformation?.employeeCode) {
          return a.officeInformation.employeeCode.localeCompare(b.officeInformation.employeeCode);
        }
        return 0;
      });
      setData(sortedData);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUsersDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!openModal) {
      fetchUsersDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  // To open User Registration Modal
  const openUserRegistrationModal = () => {
    setUserRegistrationModal(true);
  };

  // To close User Registration Modal
  const closeUserRegistrationModal = () => {
    setUserRegistrationModal(false);
  };

  // To Handle page change in pagination
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Function to handle user suspension
  const suspendUser = async (userId: string | undefined, active: Boolean) => {
    active = !active;
    if (userId) {
      setLoading(true);
      try {
        await suspendUserById(token, userId, active);
        Swal.fire({
          icon: 'success',
          title: `User ${active ? 'activated' : 'suspended'} successfully`,
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } catch (error) {
        console.error('Error suspending user:', error);
        Swal.fire({
          icon: 'error',
          title: `User ${active ? 'activated' : 'suspended'} failed`,
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } finally {
        setLoading(false);
        fetchUsersDetail();
      }
    }
  };

  const deleteUser = async (userId: string | undefined) => {
    if (userId) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1B4965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          container: 'swal-container',
        }
      });

      if (result.isConfirmed) {
        setLoading(true);
        try {
          await deleteUserById(token, userId);
          Swal.fire({
            icon: 'success',
            title: 'User Deleted Successfully',
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            }
          });
        } catch (error) {
          console.error('Error deleting user:', error);
          Swal.fire({
            icon: 'error',
            title: 'Failed to delete user',
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            }
          });
        } finally {
          setLoading(false);
          fetchUsersDetail();
        }
      }
    }
  };

  const isS3FileType = (file: File | S3FileType): file is S3FileType => {
    return (file as S3FileType)?.location !== undefined;
  };

  const columns = useMemo<MRT_ColumnDef<UserType>[]>(
    () => [
      {
        accessorKey: 'fullName', header: 'Name',
        Cell: ({ renderedCellValue, row }) => {
          const profilePic = row.original.profilePic;
          const profilePicUrl = isS3FileType(profilePic) ? profilePic.location : userImg;
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <img
                alt='avatar'
                height={40}
                width={40}
                src={profilePicUrl}
                onError={(e) => { e.currentTarget.src = userImg; }}
                loading='lazy'
                style={{ borderRadius: '50%', objectFit: 'fill' }}
              />
              {renderedCellValue}
            </Box>
          );
        },
        size: 200
      },
      { accessorKey: 'officeInformation.employeeCode', header: 'Employee Code' },
      { accessorKey: 'role', header: 'Role' },
      { accessorKey: 'workEmail', header: 'Email' },
      {
        accessorKey: 'currentStatus', header: 'Status',
        Cell: ({ renderedCellValue }) => {
          const status = renderedCellValue ?? UserStatus.Offline;
          return (
            <span style={{ backgroundColor: statusColors[status as UserStatus] || statusColors[UserStatus.Offline], padding: '4px 6px', borderRadius: '6px', color: 'white' }}>
              {status}
            </span>
          );
        },
        size: 50
      },
    ], []
  );

  // To set table header styles
  const muiTableHeadCellProps = {
    sx: {
      backgroundColor: '#1B4965',
      color: '#fff',
      fontWeight: 'bold',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
      '&:first-of-type': {
        borderTopLeftRadius: '12px',
      },
      '&:last-of-type': {
        borderTopRightRadius: '12px',
      },
      fontSize: '14px'
    },
  };

  // To set table body row styles
  const muiTableBodyRowProps = (row: any) => ({
    sx: {
      backgroundColor: getRowBackgroundColor(row),
    }
  })

  // To set table body cell styles
  const muiTableBodyCellProps = (row: any) => ({
    sx: {
      fontSize: '13px',
    }
  })

  // To set background color for even rows in tables
  const getRowBackgroundColor = (row: any) => {
    return row?.row?.index % 2 === 0 ? "#ffffff" : "#d6e1ed"
  };

  // To Render table
  const table = useMaterialReactTable({
    columns,
    data: data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    enableTopToolbar: false,
    muiTableHeadCellProps,
    muiTableBodyRowProps,
    muiTableBodyCellProps,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: false,
    enableRowActions: true,
    renderBottomToolbarCustomActions: () => (
      <Pagination
        count={data.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    ),
    initialState: {
      density: 'compact',
      columnPinning: { right: ['mrt-row-actions'] }
    },
    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: -1,
      },
    }),
    muiTableContainerProps: {
      sx: {
        maxHeight: '65vh',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#1B4965',
          borderRadius: '20px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f0f0f0',
        },
      },
    },
    renderRowActionMenuItems: ({ row, closeMenu }) => {
      const { _id, isActive } = row.original;
      return [
        <MenuItem
          key={0}
          onClick={() => {
            navigate(`/subscriber/user-details/${_id}`);
            closeMenu();
          }}
          sx={{ m: 0, fontSize: '13px' }}
        >
          <ListItemIcon>
            <AccountCircle style={{ fontSize: '18px' }} />
          </ListItemIcon>
          View Profile
        </MenuItem>,
        // <MenuItem
        //   key={1}
        //   onClick={() => {
        //     alert("Write send email logic")
        //     closeMenu();
        //   }}
        //   sx={{ m: 0, fontSize: '13px' }}
        // >
        //   <ListItemIcon>
        //     <Send style={{ fontSize: '18px' }} />
        //   </ListItemIcon>
        //   Send Email
        // </MenuItem>,
        <MenuItem
          key={2}
          onClick={() => {
            suspendUser(_id, isActive)
            closeMenu();
          }}
          sx={{ m: 0, fontSize: '13px' }}
        >
          <ListItemIcon>
            {isActive ? <BlockIcon style={{ fontSize: '18px' }} /> : <NotificationsIcon style={{ fontSize: '18px' }} />}
          </ListItemIcon>
          {isActive ? 'Suspend User' : 'Activate User'}
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            deleteUser(_id)
            closeMenu();
          }}
          sx={{ m: 0, fontSize: '13px' }}
        >
          <ListItemIcon>
            <DeleteForeverIcon style={{ fontSize: '18px' }} />
          </ListItemIcon>
          Delete User
        </MenuItem>,
      ];
    },
  });

  return (
    <div>
      <div className='companyadmin-dashboard'>
        <div className='companyadmin-title'>
          <p>Users List</p>
        </div>
        <button className='companyadmin-adduser' onClick={openUserRegistrationModal}>Add User</button>
      </div>
      <div className='companyadmin-usertable'>
        <MaterialReactTable table={table} />
      </div>
      <UserRegistration open={openModal} onClose={closeUserRegistrationModal} />
      {(loading) && (
        <Loader />
      )}
    </div>
  )
}

export default Userslist