import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { getuserDetailsByTabName, updateUser } from '../../../../utils/Services/userServices'

function WorkExperience() {
  const [workExperienceDetails, setWorkExperienceDetails] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { userId } = useParams();
  const tabName = "workExperience"

  // To get work experience details
  const fetchWorkExperienceDetails = async () => {
    setLoading(true);
    try {
      const response = await getuserDetailsByTabName(token, tabName, userId);
      const data = response?.data?.officeInformation?.previousWorkExperience || [];
      setWorkExperienceDetails(data);
    } catch (err) {
      console.error('Error fetching work experience details:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user work expeience details',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkExperienceDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle to edit the fields
  const handleEditClick = async () => {
    if (isEditing) {
      await fetchWorkExperienceDetails();
    }
    setIsEditing(prevEditing => {
      const newEditingState = !prevEditing;
      if (newEditingState) {
        Swal.fire({
          icon: 'info',
          title: 'Editing Enabled',
          text: 'Now you can edit the fields.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      }
      return newEditingState;
    });
  };

  // To Calculate No Of Years
  const calculateDuration = (fromDate, toDate) => {
    const start = new Date(fromDate);
    const end = new Date(toDate);

    const diffTime = Math.abs(end - start);
    const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
    const diffMonths = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));

    return `${diffYears} years ${diffMonths} months`;
  };

  // To handle No of years
  const handleDurationChange = (index, field, value) => {
    const updatedWorkExperience = [...workExperienceDetails];
    updatedWorkExperience[index] = { ...updatedWorkExperience[index], [field]: value };

    let duration = '';
    if (updatedWorkExperience[index].durationFrom && updatedWorkExperience[index].durationTo) {
      duration = calculateDuration(updatedWorkExperience[index].durationFrom, updatedWorkExperience[index].durationTo);
    }

    updatedWorkExperience[index].noOfYears = duration;
    setWorkExperienceDetails(updatedWorkExperience);
  };

  // To Add rows
  const handleAddData = () => {
    setWorkExperienceDetails([
      ...workExperienceDetails,
      { companyName: '', designation: '', durationFrom: '', durationTo: '', noOfYears: '' }
    ]);
  };

  // To remove the rows
  const handleRemoveData = (index) => {
    const updatedWorkExperience = workExperienceDetails.filter((_, i) => i !== index);
    setWorkExperienceDetails(updatedWorkExperience);
  };

  // Hanlde Update
  const handleUpdate = async () => {
    setLoading(true);
    try {
      const data = { previousWorkExperience: workExperienceDetails, }
      const updatedData = { data }
      await updateUser(token, userId, tabName, updatedData);
      Swal.fire({
        icon: 'success',
        title: 'Work Experience Details Updated',
        text: 'The work experience details have been successfully updated.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'There was an error updating the work experience details. Please try again.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
      console.error('Error updating work experence details:', err);
    } finally {
      setLoading(false);
      setIsEditing(false);
      fetchWorkExperienceDetails();
    }
  };

  return (
    <span>
      <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
        <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
          <Grid item>
            {isEditing && (
              <Button variant="contained"
                sx={{
                  backgroundColor: '#51b86c',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#51b86c',
                    opacity: 1
                  }
                }}
                endIcon={<AddCircleIcon />}
                onClick={handleAddData}>Add
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button variant="contained"
              sx={{
                backgroundColor: '#5A9BD5',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#5A9BD5',
                  opacity: 1
                }
              }}
              endIcon={<EditIcon />}
              onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
            </Button>
          </Grid>
          <Grid item>
            {isEditing && (
              <Button variant="outlined"
                sx={{
                  backgroundColor: '#1B4965',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#1B4965',
                    opacity: 1
                  }
                }}
                endIcon={<RestartAltIcon />}
                onClick={() => {
                  if (isEditing) {
                    handleUpdate();
                  }
                }}
              > Update
              </Button>
            )}
          </Grid>
        </Grid>
        {workExperienceDetails.length === 0 && !isEditing && (
          <NoDataMessage>No work experience data submitted yet. Please click "Edit" to add details.</NoDataMessage>
        )}
        {workExperienceDetails.map((experience, index) => (
          <Grid container spacing={2} alignItems="center" key={index}>
            <Grid item xs={3}>
              <TextField required id={`companyName-${index}`} name="companyName" label="Company Name" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => handleDurationChange(index, 'companyName', e.target.value)} value={experience.companyName || ''}
                sx={{
                  '& .MuiInputLabel-root': { fontSize: '14px' },
                  '& .MuiInputBase-root': { fontSize: '14px' },
                  width: '100%'
                }}
              />
            </Grid>
            <Grid item xs={3} alignItems='center' sx={{ display: 'flex' }}>
              <Grid item xs={6}>
                <TextField required id={`durationFrom-${index}`} label="Duration From" type="date" name="durationFrom" value={experience.durationFrom ? experience.durationFrom.split('T')[0] : ''} onChange={(e) => handleDurationChange(index, 'durationFrom', e.target.value)} InputLabelProps={{ shrink: true }} disabled={!isEditing} fullWidth margin="dense" variant="outlined" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' } }} />
              </Grid>
              <Grid item xs={6}>
                <TextField required id={`durationTo-${index}`} label="Duration To" type="date" name="durationTo" value={experience.durationTo ? experience.durationTo.split('T')[0] : ''} onChange={(e) => handleDurationChange(index, 'durationTo', e.target.value)} InputLabelProps={{ shrink: true }} disabled={!isEditing} fullWidth margin="dense" variant="outlined" sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' } }} />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <TextField required id={`noOfYears-${index}`} name="noOfYears" label="No Of Years" variant="outlined" fullWidth margin="dense" disabled={{ readOnly: true }} value={experience.noOfYears || ''}
                sx={{
                  '& .MuiInputLabel-root': { fontSize: '14px' },
                  '& .MuiInputBase-root': { fontSize: '14px' },
                  width: '100%'
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField required id={`designation-${index}`} name="designation" label="Designation" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => handleDurationChange(index, 'designation', e.target.value)} value={experience.designation || ''}
                sx={{
                  '& .MuiInputLabel-root': { fontSize: '14px' },
                  '& .MuiInputBase-root': { fontSize: '14px' },
                  width: '100%'
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete" color="error" onClick={() => handleRemoveData(index)} sx={{ visibility: isEditing ? 'visible' : 'hidden' }}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Container>
      {(loading) && (
        <Loader />
      )}
    </span>
  )
}

export default WorkExperience

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;

const NoDataMessage = styled.div`
  font-size: 20px;
  color: #1B4965;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
`;