import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
// import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
// import ProjectOutlinedIcon from '@mui/icons-material/WorkOutline'
import UserOutlinedIcon from '@mui/icons-material/PersonOutline'
import ClockOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PaidIcon from '@mui/icons-material/Paid';
// import FileOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
// import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
// import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
// import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TimelapseIcon from '@mui/icons-material/Timelapse';

import './DynamicSidebar.css'
import RightArrow from '../../../../../assets/DynamicSidebar/right-arrows.svg'
import LeftArrow from '../../../../../assets/DynamicSidebar/left-arrows.svg'
import { Roles } from '../../../../../utils/constant';
import { selectUser } from '../../../../../Redux/selector';

const DynamicSidebar = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(true)
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();

  const superAdminListValues = [
    { title: 'Dashboard', icon: <HomeOutlinedIcon className='sidebar-icon' />, path: '/systemadmin' },
    { title: 'Subscribers', icon: <GroupOutlinedIcon className='sidebar-icon' />, path: '/systemadmin/subscriberList' },
    // { title: 'Permissions', icon: <ContactMailOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Plans', icon: <FactCheckOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Announcements', icon: <ProjectOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Payments', icon: <LocalAtmOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Settings', icon: <SettingsOutlinedIcon className='sidebar-icon' /> },
  ]

  const subscriberListValues = [
    { title: 'Dashboard', icon: <HomeOutlinedIcon className='sidebar-icon' />, path: `/subscriber/${user?.companyId?._id}` },
    { title: 'Users', icon: <GroupOutlinedIcon className='sidebar-icon' />, path: `/subscriber/usersList/${user?.companyId?._id}` },
    // { title: 'Clients', icon: <ApartmentOutlinedIcon className='sidebar-icon' />, path: `/subscriber/clientsList/${user?.companyId?._id}` },
    // { title: 'Projects', icon: <ProjectOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Tasks', icon: <FactCheckOutlinedIcon className='sidebar-icon' /> },
    { title: 'Shifts', icon: <TimelapseIcon className='sidebar-icon' />, path: `/subscriber/shift/${user?.companyId?._id}` },
    { title: 'Attendance', icon: <UserOutlinedIcon className='sidebar-icon' />, path: `/subscriber/user-attendance/${user?.companyId?._id}` },
    { title: 'Leave / Timeoff', icon: <ClockOutlinedIcon className='sidebar-icon' /> },
    { title: 'Daily Reports', icon: <BarChartOutlinedIcon className='sidebar-icon' />, path: `/subscriber/daily-reports/${user?.companyId?._id}` },
    { title: 'Payroll Approvals', icon: <PaidIcon className='sidebar-icon' />, path: `/subscriber/payroll/${user?.companyId?._id}` },
    // { title: 'Permission', icon: <ContactMailOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Approvals', icon: <FileOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Billing', icon: <LocalAtmOutlinedIcon className='sidebar-icon' /> },
    { title: 'Configuration', icon: <ManageAccountsIcon className='sidebar-icon' />, path: `/subscriber/configuration/${user?.companyId?._id}` },
  ];

  const clientListValues = [
    { title: 'Dashboard', icon: <HomeOutlinedIcon className='sidebar-icon' />, path: `/client/${user?._id}` },
    // { title: 'Attendance', icon: <UserOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Leave', icon: <ClockOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Tasks', icon: <FactCheckOutlinedIcon className='sidebar-icon' /> },
  ];

  const ClientAdminListValues = [
    { title: 'Dashboard', icon: <HomeOutlinedIcon className='sidebar-icon' />, path: `/clientadmin/${user?.companyId?._id}` },
    { title: 'Coordinators', icon: <GroupOutlinedIcon className='sidebar-icon' />, path: `/clientadmin/coordinatorList/${user?.companyId?._id}` },
    { title: 'Attendance', icon: <UserOutlinedIcon className='sidebar-icon' /> },
  ];

  const generalUserListValues = [
    { title: 'Dashboard', icon: <HomeOutlinedIcon className='sidebar-icon' />, path: `/user/${user?._id}` },
    // { title: 'Projects', icon: <ProjectOutlinedIcon className='sidebar-icon' /> },
    // { title: 'Tasks', icon: <FactCheckOutlinedIcon className='sidebar-icon' /> },
    { title: 'Attendance', icon: <UserOutlinedIcon className='sidebar-icon' />, path: `/user/attendance/${user?._id}` },
    // { title: 'Approvals', icon: <FileOutlinedIcon className='sidebar-icon' /> },
    { title: 'Daily Reports', icon: <BarChartOutlinedIcon className='sidebar-icon' />, path: `/user/daily-reports/${user?._id}` },
    // { title: 'Leave / Timeoff', icon: <ClockOutlinedIcon className='sidebar-icon' />, path: `/user/leave-timeoff/${user?._id}` },
    // { title: 'Expenses', icon: <LocalAtmOutlinedIcon className='sidebar-icon' /> },
  ];

  let listValues;
  if (user?.role === Roles.SYSTEM_SUPER_ADMIN) {
    listValues = superAdminListValues;
  } else if (location.pathname.startsWith('/subscriber/')) {
    listValues = subscriberListValues;
  } else if (location.pathname.startsWith('/client/')) {
    listValues = clientListValues;
  } else if (location.pathname.startsWith('/clientadmin/')) {
    listValues = ClientAdminListValues;
  } else {
    listValues = generalUserListValues;
  }

  const handleClick = (path) => {
    navigate(path);
  }

  return (
    <div style={{ display: 'flex', overflowX: 'hidden', height: '100%', width: '100%' }}>
      <div className='dynamicsidebar' style={isSidebarOpened ? { width: '10%', minWidth: '12vw', transition: '0.1s' } : { width: '60px' }}>
        <div>
          {listValues.map((item, index) => (
            <div key={index} className={`dymanicsidebar-icons ${location.pathname === item.path ? 'active' : ''} ${ item?.path?.includes('subscriber/payroll') && location.pathname.includes('subscriber/payroll') ? "active" : ""}`} onClick={() => item.path && handleClick(item.path)}>
              {item.icon}
              {isSidebarOpened && <span>{item.title}</span>}
            </div>
          ))}
        </div>
        <div className='arrow-container'>
          {isSidebarOpened ? (
            <div className='right-div'>
              <img src={RightArrow} alt="Collapse Sidebar" className='rightarrow' onClick={() => setIsSidebarOpened(false)} />
            </div>
          ) : (
            <div className='left-div'>
              <img src={LeftArrow} alt="Expand Sidebar" className='leftarrow' onClick={() => setIsSidebarOpened(true)} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DynamicSidebar
