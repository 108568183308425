import React from 'react'
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Redux/selector';

const PayrollTable = () => {

  const user = useSelector(selectUser);
  const subscriberId = user?.companyId?._id

  return (
    <div>PayrollTable</div>
  )
}

export default PayrollTable