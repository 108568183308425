import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/shift`;

// To create a new shift
export const createNewShift = (token, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/create-shift/${subscriberId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get created shifts
export const getAllShift = (token, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-shift/${subscriberId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To update shifts
export const updateShift = (token, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.put(`${backEndUrl}/update-shift/${subscriberId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To delete shift
export const deleteShift = (token, shiftId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.delete(`${backEndUrl}/delete-shift/${shiftId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// Assign Shift to User
export const assignShift = (token, subscriberId, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/assign-shift/${subscriberId}/${userId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// get assigned shift to user
export const getAssignedShift = (token, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-assigned-shift/${subscriberId}`, { headers, params: data });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// get assigned shift based on user
export const getShift = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/getshift/${userId}`, {
                headers,
                params: data
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};