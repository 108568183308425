import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import { Box, Button, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { addYearlyCalendarEvent, deleteYearlyCalendarEvent, getYearlyCalendarEvent, updateYearlyCalendarEvent } from '../../../../utils/Services/subscriberConfigurationServices/yearlyCalendarServices';
import Swal from 'sweetalert2';

function YearlyCalendar() {
    const [events, setEvents] = useState([]);
    const [newEventTitle, setNewEventTitle] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventType, setEventType] = useState('event');
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { subscriberId } = useParams();

    const fetchEvents = async () => {
        try {
            setLoading(true)
            const response = await getYearlyCalendarEvent(token, subscriberId);
            const formattedEvents = response.data.map(event => {
                const formattedDate = new Date(event.date).toISOString().split('T')[0];
                return { ...event, date: formattedDate };
            });
            setEvents(formattedEvents);

        } catch (err) {
            console.error("Failed to fetch events", err);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    const handleDateClick = (info) => {
        setSelectedDate(info.dateStr);
        setIsModalOpen(true);
        setSelectedEvent(null);
        setNewEventTitle('');
        setEventType('event');
    };

    const handleEventClick = (info) => {
        setSelectedEvent(info.event);
        setNewEventTitle(info.event.title);
        setSelectedDate(info.event.startStr);
        setEventType(info.event.extendedProps.type || 'event');
        setIsModalOpen(true);
    };

    const handleAddEvent = async () => {
        if (newEventTitle && selectedDate) {
            const event = { title: newEventTitle, date: selectedDate, type: eventType };
            try {
                setLoading(true)
                await addYearlyCalendarEvent(token, subscriberId, event);
                setIsModalOpen(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Event Added Successfully!',
                    text: 'New event added to calendar.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            } catch (err) {
                console.error("Failed to add event", err);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed ',
                    text: 'Failed to add event',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            } finally {
                setLoading(false)
                fetchEvents();
            }
        }
    };

    const handleUpdateEvent = async () => {
        if (newEventTitle && selectedEvent) {
            const updatedEvent = {
                eventId: selectedEvent?._def?.extendedProps?._id,
                title: newEventTitle,
                type: eventType
            };
            try {
                setLoading(true)
                await updateYearlyCalendarEvent(token, subscriberId, updatedEvent);
                setIsModalOpen(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Event Updated Successfully!',
                    text: 'Event updated to calendar.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            } catch (err) {
                console.error("Failed to update event", err);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed ',
                    text: 'Failed to update event',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            } finally {
                setLoading(false)
                fetchEvents();
            }
        }
    };

    const handleDeleteEvent = async () => {
        if (selectedEvent) {
            try {
                setLoading(true)
                const eventId = selectedEvent?._def?.extendedProps?._id;
                await deleteYearlyCalendarEvent(token, subscriberId, eventId);
                setIsModalOpen(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Event Deleted Successfully!',
                    text: 'Event deleted from calendar.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            } catch (err) {
                console.error("Failed to delete event", err);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed ',
                    text: 'Failed to delete event',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            } finally {
                setLoading(false)
                fetchEvents();
            }
        }
    };

    return (
        <span>
            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Yearly Calendar Configuration</Typography>
                </Box>
                <div style={{ height: '100vh' }}>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        editable={true}
                        selectable={true}
                        initialView="dayGridMonth"
                        contentHeight="auto"
                        dayMaxEvents={true}
                        headerToolbar={{
                            left: 'prev',
                            right: 'next',
                            center: 'title',
                        }}
                        events={events}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        dayCellClassNames={function (dateInfo) {
                            const dayOfWeek = dateInfo.date.getDay();
                            if (dayOfWeek === 6) {
                                return 'saturday-cell';
                            } else if (dayOfWeek === 0) {
                                return 'sunday-cell';
                            } else {
                                return 'weekday-cell'
                            }
                        }}
                    />
                </div>
                <style>
                    {`
                    .saturday-cell {
                        background-color: #f0f8ff; /* Light blue for Saturday */
                    }
                    .sunday-cell {
                        background-color: #fff0f0; /* Light pink for Sunday */
                    }
                    .weekday-cell {
                        background-color: #e0f7fa; /* Light cyan for Monday to Friday */
                    }
                `}
                </style>

            </Container>

            {isModalOpen && (
                <EventModal sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1B4965' }}>
                        {selectedEvent ? 'Edit Event' : 'Add New Event'}
                    </Typography>
                    <FormControl fullWidth variant='outlined' sx={{ marginTop: '15px' }} margin='dense'>
                        <InputLabel id="eventType">Event Type</InputLabel>
                        <Select
                            labelId="eventType"
                            label="Event Type"
                            name="eventType"
                            value={eventType}
                            onChange={(e) => setEventType(e.target.value)}
                        >
                            <MenuItem value="event">Event</MenuItem>
                            <MenuItem value="publicHoliday">Public Holiday</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField label="Event Title" variant="outlined" value={newEventTitle} onChange={(e) => setNewEventTitle(e.target.value)} fullWidth sx={{ marginTop: '15px' }} />

                    <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            onClick={() => setIsModalOpen(false)}
                            sx={{ backgroundColor: '#B42626', color: 'white', width: '120px', height: '40px', fontSize: '14px', borderRadius: '5px', cursor: 'pointer', '&:hover': { backgroundColor: '#8F1F1F' } }}
                        >Cancel
                        </Button>

                        {selectedEvent ? (
                            <>
                                <Button
                                    onClick={handleDeleteEvent}
                                    sx={{ backgroundColor: '#FF4D4D', color: 'white', width: '120px', height: '40px', fontSize: '14px', borderRadius: '5px', cursor: 'pointer', '&:hover': { backgroundColor: '#FF2D2D' } }}
                                >Delete
                                </Button>

                                <Button
                                    onClick={handleUpdateEvent}
                                    sx={{ backgroundColor: '#1B4965', color: 'white', width: '120px', height: '40px', fontSize: '14px', borderRadius: '5px', cursor: 'pointer', '&:hover': { backgroundColor: '#153E5E' } }}
                                >Update
                                </Button>
                            </>
                        ) : (
                            <Button
                                onClick={handleAddEvent}
                                sx={{ backgroundColor: '#1B4965', color: 'white', width: '120px', height: '40px', fontSize: '14px', borderRadius: '5px', cursor: 'pointer', '&:hover': { backgroundColor: '#153E5E' } }}
                            >Add Event
                            </Button>
                        )}
                    </Box>
                </EventModal>
            )}
            {loading && <Loader />}
        </span>
    );
}

export default YearlyCalendar;

const Container = styled.div`
  padding: 20px;
  height: 80vh;
  overflow-y: auto;
`;

const EventModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 999;
  width: 400px;
`;