import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Redux/selector";
import CustomTime from "../../../Components/UiUxComponents/CustomComponents/CustomTime/CustomTime";
import CustomCalendar from "../../../Components/UiUxComponents/CustomComponents/CustomCalendar/CustomCalendar";

function UsersDashboard() {
  const user = useSelector(selectUser);

  const greeting = useMemo(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return "Good Morning";
    if (currentHour < 17) return "Good Afternoon";
    return "Good Evening";
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", padding: "20px", overflow: 'auto', marginBottom: '50px' }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", marginBottom: "20px" }}>
        <div style={{ fontSize: "25px", fontWeight: "bold", color: "#1B4965", backgroundColor: "white", padding: "12px", borderRadius: "8px", display: "flex", alignItems: "center", height: "60px", boxSizing: "border-box", }}>
          {greeting}, {user?.firstName || "Guest"}!
        </div>
        <div style={{ display: "flex", alignItems: "center", height: "60px" }}>
          <CustomTime />
        </div>
      </div>
      <div style={{ width: "100%", height: '72vh' }}>
        <CustomCalendar />
      </div>
    </div>
  );
}

export default UsersDashboard;
