import React from 'react'
import Fixedsidebar from '../SidebarComponents/FixedSidebar/Fixedsidebar'
import DynamicSidebar from '../SidebarComponents/DynamicSidebar/DynamicSidebar'
import { Outlet } from 'react-router-dom'
import Notificationbar from '../../../NotificationBar/Notificationbar'

function SidebarContainer() {
  return (
    <div style={{ display: 'flex', width: '100%', height: 'calc(100vh)', overflowY: 'hidden' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content', marginTop: '60px' }}>
        <Fixedsidebar />
        <DynamicSidebar />
      </div>
      <div style={{ flex: 1, width: '100%', marginTop: '60px' }}>
        <Notificationbar />
        <Outlet />
      </div>
    </div>
  )
}

export default SidebarContainer
