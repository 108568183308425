import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/subscriber-configuration/salary-structure`;

// To Setup Salary Structure
export const salaryStructureSetup = (token, subscriberId, salaryEntries) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.post(`${backEndUrl}/salary-structure-setup/${subscriberId}`, salaryEntries, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To get Salary Structure
export const getSalaryStructure = (token, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            };
            let response = await axios.get(`${backEndUrl}/get-salary-structure/${subscriberId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};