import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/salary`;

// To Add/Update a user salary breakdown
export const addSalaryBreakdown = (token, userId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/add-salary-dreakdown/${userId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Get a user salary breakdown
export const getSalaryBreakdown = (token, userId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-salary-dreakdown/${userId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};