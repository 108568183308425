import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/import-export`;

// To Download Users Data(Export to Excel)
export const downloadUsersData = (token, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/export-excel-users-data/${subscriberId}`, {
                headers,
                responseType: 'blob'
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};
