import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import styled from 'styled-components';
import { Box, Card, CardContent, Grid, Typography, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../../Redux/selector';
import { getSalaryStructure } from '../../../../utils/Services/subscriberConfigurationServices/salaryStructureServices';
import { addSalaryBreakdown, getSalaryBreakdown } from '../../../../utils/Services/salaryServices';

function SalaryDetailsAdmin() {

    const [values, setValues] = useState({ monthlyCTC: 0, yearlyCTC: 0, grossSalaryPM: 0, grossSalaryPA: 0, tableData: [] });
    const [salaryBreakdown, setSalaryBreakdown] = useState()
    const [salaryStructure, setSalaryStructure] = useState()
    const [editField, setEditField] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [descriptionModified, setDescriptionModified] = useState(false);
    const [loading, setLoading] = useState(false);
    const { userId } = useParams();
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);

    const subscriberId = user?.companyId?._id;

    const fetchSalaryStructure = async () => {
        try {
            setLoading(true);
            const response = await getSalaryStructure(token, subscriberId);

            if (response && response.data.salaryEntries?.length > 0) {
                setSalaryStructure(response?.data?.salaryEntries)
            }
        } catch (error) {
            console.error('Error fetching salary structure:', error.response?.data?.message || error.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchSalaryBreakdown = async () => {
        try {
            setLoading(true);
            const response = await getSalaryBreakdown(token, userId);

            if (response?.data?.salary) {
                setSalaryBreakdown(response?.data?.salary)
            }
        } catch (error) {
            console.error('Error fetching salary structure:', error.response?.data?.message || error.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSalaryStructure();
    }, []);

    useEffect(() => {
        fetchSalaryBreakdown();
    }, []);

    useEffect(() => {
        if (salaryStructure && salaryStructure.length === 0) return;

        let monthlyCTC = salaryBreakdown?.currentMonthlyCTC || 0;
        let yearlyCTC = salaryBreakdown?.currentYearlyCTC || 0;
        let grossSalaryPA = salaryBreakdown?.grossSalaryPA || 0;
        let grossSalaryPM = salaryBreakdown?.grossSalaryPM || 0;

        let tableData = [];
        let modified = false;

        if (salaryBreakdown?.salaryBreakUp?.length > 0) {
            const breakdownMap = new Map(
                salaryBreakdown.salaryBreakUp.map(item => [item.description, item])
            );

            tableData = salaryStructure && salaryStructure.map(item => {
                let value = item.value;
                let amountPM;
                let amountPA

                if (breakdownMap.has(item.description)) {
                    const existing = breakdownMap.get(item.description);
                    value = existing.value;
                    amountPM = existing.amountPM;
                    amountPA = existing.amountPA;

                } else {
                    modified = true;
                }

                return {
                    description: item.description,
                    value: value,
                    type: item.type,
                    amountPM: yearlyCTC ? amountPM : 0,
                    amountPA: yearlyCTC ? amountPA : 0
                };
            });
        } else {

            tableData = salaryStructure && salaryStructure.map(item => ({
                description: item.description,
                value: item.value,
                type: item.type,
                amountPM: yearlyCTC ? ((item.type === "Employer Contribution" || item.type === "Deduction") ? Number(item.value) : (item.value / 100) * monthlyCTC) : 0,
                amountPA: yearlyCTC ? ((item.type === "Employer Contribution" || item.type === "Deduction") ? Number(item.value) * 12 : (item.value / 100) * yearlyCTC) : 0
            }));
        }

        setDescriptionModified(modified)

        setValues({ yearlyCTC, monthlyCTC, grossSalaryPM, grossSalaryPA, tableData });

    }, [salaryBreakdown, salaryStructure]);

    const formatNumber = (num) => {
        return new Intl.NumberFormat().format(num);
    };

    const handleDoubleClick = (field) => {
        if (isEditing) {
            setEditField(field);
        }
    };

    const handleChange = (e, field, description) => {
        let newValue = e.target.value.replace(/,/g, '');
        if (newValue === '') newValue = '0';

        const numberValue = Number(newValue);

        setValues((prevValues) => {
            let newValues = { ...prevValues };
            if (field === 'yearlyCTC') {
                newValues.yearlyCTC = numberValue;
                newValues.monthlyCTC = numberValue / 12;

                const employeeContributions = salaryStructure
                    .filter(item => item.type === "Employer Contribution")
                    .reduce((sum, item) => sum + Number(item.value), 0);

                newValues.grossSalaryPM = newValues.monthlyCTC - employeeContributions;
                newValues.grossSalaryPA = (newValues.grossSalaryPM) * 12
            }
            else if (field === 'value' && description) {
                newValues.tableData = prevValues.tableData.map((row, idx) => {
                    if (row.description === description) {
                        const updatedRow = { ...row, value: numberValue };

                        let grossSalaryPM = newValues.grossSalaryPM;

                        updatedRow.amountPM = (row.type === "Employer Contribution" || row.type === "Deduction") ? Number(updatedRow.value) : Math.round((updatedRow.value / 100) * grossSalaryPM);

                        updatedRow.amountPA = (row.type === "Employer Contribution" || row.type === "Deduction") ? Number(updatedRow.value) * 12 : Math.round((updatedRow.value / 100) * grossSalaryPM) * 12;

                        return updatedRow;
                    }
                    return row;
                });
            }

            if (field === 'monthlyCTC' || field === 'yearlyCTC') {
                newValues.tableData = prevValues.tableData.map((row) => {
                    let amountPM, amountPA;

                    if (row.type === "Earning") {
                        amountPM = Math.round((row.value / 100) * newValues.grossSalaryPM);
                        amountPA = amountPM * 12;
                    } else if (row.type === "Employer Contribution") {
                        amountPM = Number(row.value);
                        amountPA = Number(row.value) * 12;
                    } else if (row.type === "Deduction") {
                        amountPM = Number(row.value);
                        amountPA = Number(row.value) * 12;
                    }

                    amountPM = Math.round(amountPM * 100) / 100;
                    amountPA = Math.round(amountPA * 100) / 100;

                    return { ...row, amountPM, amountPA };
                });
            }

            return newValues;
        });
    };

    const handleBlur = () => {
        setEditField(null);
    };

    const handleEditClick = () => {
        setIsEditing((prev) => !prev);
    };

    const handleUpdateClick = async () => {
        if (values.monthlyCTC === 0 || values.yearlyCTC === 0 || values.grossSalaryPM === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing CTC values!',
                text: 'Please enter Yearly CTC before submitting.',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                },
            });
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to update the salary details. Do you want to continue?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Update',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#1B4965',
            customClass: {
                container: 'swal-container',
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsEditing(false);
                setLoading(true);
                try {
                    await addSalaryBreakdown(token, userId, {
                        ...values,
                        costToCompanyPM: costToCompany,
                        costToCompanyPA: costToCompany * 12,
                        netHomePM: approxNetTakeHome,
                        netHomePA: approxNetTakeHome * 12,
                    });

                    Swal.fire({
                        title: 'Updated!',
                        text: 'The salary details have been updated.',
                        icon: 'success',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        },
                    });
                } catch (error) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'There was an issue updating the salary details. Please try again.',
                        icon: 'error',
                        confirmButtonColor: '#B42626',
                        customClass: {
                            container: 'swal-container',
                        },
                    });
                    console.error('Error updating salary details:', error);
                } finally {
                    setLoading(false)
                    fetchSalaryStructure();
                    fetchSalaryBreakdown();
                }
            }
        });
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditField(null);
        fetchSalaryStructure();
        fetchSalaryBreakdown();
    };

    const handleUpdateLatestRecordClick = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to update the salary details. Do you want to continue?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Update',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#1B4965',
            customClass: {
                container: 'swal-container',
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsEditing(false);
                setLoading(true);
                try {
                    await addSalaryBreakdown(token, userId, {
                        ...values,
                        costToCompanyPM: values.costToCompanyPM,
                        costToCompanyPA: values.costToCompanyPA,
                        netHomePM: values.netHomePM,
                        netHomePA: values.netHomePA,
                    });

                    Swal.fire({
                        title: 'Updated!',
                        text: 'The salary details have been updated.',
                        icon: 'success',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        },
                    });
                } catch (error) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'There was an issue updating the salary details. Please try again.',
                        icon: 'error',
                        confirmButtonColor: '#B42626',
                        customClass: {
                            container: 'swal-container',
                        },
                    });
                    console.error('Error updating salary details:', error);
                } finally {
                    setLoading(false)
                    fetchSalaryStructure();
                    fetchSalaryBreakdown();
                }
            }
        });
    };

    const typeOrder = ["Earning", "Employer Contribution", "Deduction"];

    const totalEmployerContribution = (values.tableData || [])
        .filter(row => row.type === "Employer Contribution")
        .reduce((sum, row) => sum + Number(row.amountPM), 0);

    const costToCompany = (values.grossSalaryPM || 0) + totalEmployerContribution;

    const totalEmployeeContribution = (values.tableData || [])
        .filter(row => row.type === "Deduction")
        .reduce((sum, row) => sum + Number(row.amountPM), 0);

    const approxNetTakeHome = (values.grossSalaryPM || 0) - totalEmployeeContribution;

    const groupedData = (values.tableData || []).reduce((acc, row) => {
        acc[row.type] = acc[row.type] || [];
        acc[row.type].push(row);
        return acc;
    }, {});

    return (
        <span>
            <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
                <Card sx={{ position: 'relative' }}>
                    <CardContent>
                        <Grid container spacing={4} alignItems="flex-start">
                            <Grid item xs={8}>
                                <Box display="flex" alignItems="center">
                                    {editField === 'yearlyCTC' ? (
                                        <>
                                            <Label color="#000" fontWeight="bold">Yearly CTC:- </Label>
                                            <TextField variant="outlined" size="small" onBlur={handleBlur} autoFocus
                                                value={formatNumber(values.yearlyCTC)}
                                                onChange={(e) => handleChange(e, 'yearlyCTC')}
                                                inputProps={{ pattern: "[0-9,]*" }}
                                            />
                                        </>
                                    ) : (
                                        <Typography variant="h6" fontWeight="bold" onDoubleClick={() => handleDoubleClick('yearlyCTC')} style={{ cursor: isEditing ? 'pointer' : 'default' }}>
                                            Yearly CTC:- ₹{formatNumber(values.yearlyCTC)}.00
                                        </Typography>
                                    )}
                                </Box>
                                <Box display="flex" alignItems="center" marginTop={1}>
                                    <Typography variant="body2" color="textSecondary">
                                        Monthly CTC:- ₹{formatNumber(values.monthlyCTC)}.00
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" marginTop={1}>
                                    <Typography variant="body2" color="textSecondary">
                                        Gross Salary(per Month):- ₹{formatNumber(values.grossSalaryPM)}.00
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" marginTop={1}>
                                    <Typography variant="body2" color="textSecondary">
                                        Gross Salary(per Year):- ₹{formatNumber(values.grossSalaryPM * 12)}.00
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Box sx={{ position: 'absolute', top: '20px', right: '20px' }}>
                        <Button variant="contained" style={{ marginRight: isEditing ? '0' : '10px' }} endIcon={isEditing ? <RestartAltIcon /> : <EditIcon />} onClick={isEditing ? handleUpdateClick : handleEditClick}
                            sx={{
                                backgroundColor: isEditing ? '#1B4965' : '#5A9BD5',
                                color: '#fff',
                                '&:hover': { backgroundColor: isEditing ? '#1B4965' : '#5A9BD5', opacity: 1 },
                            }}
                        >{isEditing ? 'Update' : 'Edit'}
                        </Button>

                        {isEditing && (
                            <Button variant="contained" style={{ marginLeft: '10px' }} onClick={handleCancelClick} endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor: '#5A9BD5',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#5A9BD5', opacity: 1 },
                                }}
                            >Cancel
                            </Button>
                        )}

                        {descriptionModified && (
                            <Button variant="contained" style={{ marginLeft: '10px' }} onClick={handleUpdateLatestRecordClick}
                                sx={{
                                    backgroundColor: '#B42626',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#B42626', opacity: 1 },
                                }}
                            >Update Latest Record
                            </Button>
                        )}
                    </Box>
                </Card>
                <Box marginTop={4}>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>Salary Breakdown</Typography>
                    {Object.keys(groupedData)
                        .sort((a, b) => typeOrder.indexOf(a) - typeOrder.indexOf(b))
                        .map((type, typeIndex, sortedKeys) => (
                            <Box key={type} marginTop={4}>
                                <Typography variant="h6" fontWeight="bold" gutterBottom>{type}</Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Amount (PM)</TableCell>
                                            <TableCell>Amount (PA)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupedData[type].map((row, index) => (
                                            <TableRow key={type + index}>
                                                <TableCell>
                                                    <Typography variant="body2" color="textSecondary">{row.description}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {editField === `value-${index}-${row.description}` ? (
                                                        <TextField
                                                            type="number"
                                                            value={row.value}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => handleChange(e, 'value', row.description)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {row.type === "Employer Contribution" || row.type === "Deduction" ? "₹" : "%"}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    ) : (
                                                        <Typography onDoubleClick={() => handleDoubleClick(`value-${index}-${row.description}`)} style={{ cursor: isEditing ? 'pointer' : 'default' }}>
                                                            {row.type === "Employer Contribution" || row.type === "Deduction" ? `₹${row.value}` : `${row.value}%`}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>₹{row.amountPM}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>₹{row.amountPA}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {type === "Earning" && sortedKeys[typeIndex + 1] === "Employer Contribution" && (
                                            <TableRow style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">Gross Salary</Typography>
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                    <Typography fontWeight="bold">₹{values.grossSalaryPM}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontWeight="bold">₹{values.grossSalaryPM * 12}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {type === "Employer Contribution" && sortedKeys[typeIndex + 1] === "Deduction" && (
                                            <TableRow style={{ backgroundColor: "#d0f0c0", fontWeight: "bold" }}>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">Cost to Company (CTC)</Typography>
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                    <Typography fontWeight="bold">₹{costToCompany}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontWeight="bold">₹{costToCompany * 12}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {type === "Deduction" && (
                                            <TableRow style={{ backgroundColor: "#ffc0cb", fontWeight: "bold" }}>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">Approx. Net Take Home</Typography>
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                    <Typography fontWeight="bold">₹{approxNetTakeHome}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontWeight="bold">₹{approxNetTakeHome * 12}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                        ))}
                </Box>
            </Container>
            {loading && <Loader />}
        </span>
    );
}

export default SalaryDetailsAdmin;

const Container = styled.div`
  padding: 20px;
  height: 70vh;
`;

const Label = styled(Typography)`
  color: ${(props) => props.color};
  font-weight: bold;
  margin-right: 8px;
`;