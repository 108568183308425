import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Grid, Box, Autocomplete, TablePagination } from '@mui/material';
import { CheckCircle, Info } from '@mui/icons-material';

import { selectToken } from '../../../../Redux/selector';
import { getAllUsersDetails } from '../../../../utils/Services/userServices';
import Loader from '../../../../Components/UiUxComponents/Loader/Loader';
import { getAllUserAttendanceData } from '../../../../utils/Services/attendanceServices';
import { format } from '../../../../utils/CustomPackages/dateUtils';

function AllEmployeesAttendance() {
    const [usersData, setUsersData] = useState([]);
    const [userAttendanceData, setUserAttendanceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(getCurrentDate());
    const [selectedEmployee, setSelectedEmployee] = useState({ fullName: "All" });

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;

    const token = useSelector(selectToken);
    const { subscriberId } = useParams();

    function getCurrentDate() {
        const now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        return now.toISOString().split("T")[0];
    }

    useEffect(() => {
        fetchUserDetail();
    }, []);

    useEffect(() => {
        fetchAttendanceData();
    }, [fromDate]);

    useEffect(() => {
        mergeData();
    }, [usersData, userAttendanceData, selectedEmployee]);

    const fetchUserDetail = async () => {
        try {
            setLoading(true);
            const response = await getAllUsersDetails(token, subscriberId);

            const activeUsers = (response?.data?.data || []).filter(user => user.isActive);

            const sortedData = activeUsers.sort((a, b) =>
                (a?.officeInformation?.employeeCode || '').localeCompare(
                    b?.officeInformation?.employeeCode || '',
                    undefined,
                    { numeric: true }
                )
            );

            setUsersData([{ fullName: "All" }, ...sortedData]);
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error fetching data.',
                confirmButtonColor: '#1B4965'
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchAttendanceData = async () => {
        setLoading(true);
        try {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const response = await getAllUserAttendanceData(token, subscriberId, { weekStart: fromDate, weekEnd: fromDate, timeZone });
            setUserAttendanceData(response?.data?.attendance || []);
        } catch (error) {
            console.error('Error fetching attendance data:', error);
            Swal.fire({ icon: 'error', title: 'Failed', text: 'Failed to fetch attendance', confirmButtonColor: '#1B4965' });
        } finally {
            setLoading(false);
        }
    };

    const getDateRange = (startDate) => {
        let dates = [];
        let currentDate = new Date(startDate);
        for (let i = 0; i < 1; i++) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const mergeData = () => {
        const dateRange = getDateRange(fromDate);
        let mergedData = usersData.slice(1).map(user => ({
            ...user,
            attendance: dateRange.map(date => {
                const attRecord = userAttendanceData.find(att => att.userID === user._id &&
                    new Date(att.clockInTime).toDateString() === date.toDateString());
                return attRecord
                    ? { ...attRecord, date: date.toISOString().split('T')[0] }
                    : { date: date.toISOString().split('T')[0], empty: true };
            })
        }));

        if (selectedEmployee.fullName !== "All") {
            mergedData = mergedData.filter(user => user.fullName === selectedEmployee.fullName);
        }

        setFilteredData(mergedData);
    };

    const handleEmployeeChange = (event, newValue) => {
        setSelectedEmployee(newValue || { fullName: "All" });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Box sx={{ padding: 2, height: '100%', maxHeight: '75vh', overflow: 'auto' }}>
            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField type="date" label="Select Date" fullWidth value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete value={selectedEmployee} onChange={handleEmployeeChange} options={usersData} getOptionLabel={(option) => option.fullName || ''} renderInput={(params) => <TextField {...params} label="Employee Name" fullWidth />} />
                    </Grid>
                </Grid>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Date</strong></TableCell>
                            <TableCell><strong>Employee Code</strong></TableCell>
                            <TableCell><strong>Full Name</strong></TableCell>
                            <TableCell><strong>Clock In</strong></TableCell>
                            <TableCell><strong>Clock Out</strong></TableCell>
                            <TableCell><strong>Location</strong></TableCell>
                            <TableCell><strong>Gross Hours</strong></TableCell>
                            <TableCell><strong>Arrival</strong></TableCell>
                            <TableCell><strong>Log</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user =>
                            user.attendance.map((att, index) => (
                                <TableRow key={`${user._id}-${index}`}>
                                    <TableCell>{att.date ? format(new Date(att.date), 'dd/MM/yyyy') : '-'}</TableCell>
                                    <TableCell>{user.officeInformation?.employeeCode || '-'}</TableCell>
                                    <TableCell>{user.fullName}</TableCell>
                                    <TableCell>{att.clockInTime ? format(new Date(att.clockInTime), 'hh:mm a') : '-'}</TableCell>
                                    <TableCell>{att.clockOutTime ? format(new Date(att.clockOutTime), 'hh:mm a') : '-'}</TableCell>
                                    <TableCell>{att.empty ? '-' : att.location || '-'}</TableCell>
                                    <TableCell>{att.empty ? '-' : att.grossHours || '-'}</TableCell>
                                    <TableCell>{att.empty ? '-' : att.arrival || '-'}</TableCell>
                                    <TableCell>
                                        {att.empty ? '-' : att.clockInTime && !att.clockOutTime ? (
                                            <Info color="warning" />
                                        ) : (
                                            att.clockInTime && att.clockOutTime ? <CheckCircle color="success" /> : '-'
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={filteredData.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
            />
            {loading && <Loader />}
        </Box>
    );
}

export default AllEmployeesAttendance;