import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Box, Card, CardContent, Grid, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import styled from 'styled-components';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { getSalaryBreakdown } from '../../../../utils/Services/salaryServices';

const SalaryDetails = () => {
    const [salaryBreakdown, setSalaryBreakdown] = useState();
    const [loading, setLoading] = useState(false);
    const [showBreakup, setShowBreakup] = useState(false);
    const [showEarnings, setShowEarnings] = useState(false);
    const [showDeductions, setShowDeductions] = useState(false);
    const [showEmployerContributions, setShowEmployerContributions] = useState(false);

    const token = useSelector(selectToken);
    const { userId } = useParams();

    const fetchSalaryBreakdown = async () => {
        try {
            setLoading(true);
            const response = await getSalaryBreakdown(token, userId);

            if (response?.data?.salary) {
                setSalaryBreakdown(response?.data?.salary);
            }
        } catch (error) {
            console.error('Error fetching salary structure:', error.response?.data?.message || error.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSalaryBreakdown();
    }, []);

    const YearlyCTC = salaryBreakdown?.currentYearlyCTC ?? 0;
    const monthlyCTC = salaryBreakdown?.currentMonthlyCTC ?? 0;
    const grossSalaryPM = salaryBreakdown?.grossSalaryPM ?? 0;
    const grossSalaryPA = salaryBreakdown?.grossSalaryPA ?? 0;
    const costToCompanyPM = salaryBreakdown?.costToCompanyPM ?? 0;
    const costToCompanyPA = salaryBreakdown?.costToCompanyPA ?? 0;
    const netHomePA = salaryBreakdown?.netHomePA ?? 0;
    const netHomePM = salaryBreakdown?.netHomePM ?? 0;
    const salaryBreakup = salaryBreakdown?.salaryBreakUp ?? [];

    const earnings = salaryBreakup.filter(item => item.type === 'Earning');
    const deductions = salaryBreakup.filter(item => item.type === 'Deduction');
    const employerContributions = salaryBreakup.filter(item => item.type === 'Employer Contribution');

    return (
        <Container>
            <Card sx={{ position: 'relative' }}>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={8}>
                            <Typography variant="h6" fontWeight="bold">Yearly CTC: ₹{YearlyCTC.toLocaleString()}</Typography>
                            <Typography variant="body2" color="textSecondary">Monthly CTC: ₹{monthlyCTC.toLocaleString()}</Typography>
                            <Typography variant="body2" color="textSecondary">Gross Salary (Per Month): ₹{grossSalaryPM.toLocaleString()}</Typography>
                            <Typography variant="body2" color="textSecondary">Gross Salary (Per Year): ₹{grossSalaryPA.toLocaleString()}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>

                <Box sx={{ position: 'absolute', bottom: 20, right: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ backgroundColor: '#1976D2', '&:hover': { backgroundColor: '#1565C0' }, fontWeight: 'bold' }}
                        onClick={() => setShowBreakup(!showBreakup)}
                    >
                        {showBreakup ? 'Hide BreakUp' : 'View BreakUp'}
                    </Button>
                </Box>
            </Card>

            {showBreakup && (
                <>
                    <CategoryCard>
                        <CategoryHeader onClick={() => setShowEarnings(!showEarnings)}>
                            <Typography variant="h6">Earnings</Typography>
                            <ToggleButton size="small">
                                {showEarnings ? 'Hide' : 'Show'}
                            </ToggleButton>
                        </CategoryHeader>
                        {showEarnings && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Percentage</TableCell>
                                        <TableCell>Amount (PM)</TableCell>
                                        <TableCell>Amount (PA)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {earnings.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>{item.value}%</TableCell>
                                            <TableCell>₹{item.amountPM.toLocaleString()}</TableCell>
                                            <TableCell>₹{item.amountPA.toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}

                                    <TableRow style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">Gross Salary</Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <Typography fontWeight="bold">₹{grossSalaryPM.toLocaleString()}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography fontWeight="bold">₹{grossSalaryPA.toLocaleString()}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                    </CategoryCard>


                    <CategoryCard>
                        <CategoryHeader onClick={() => setShowEmployerContributions(!showEmployerContributions)}>
                            <Typography variant="h6">Employer Contributions</Typography>
                            <ToggleButton size="small">
                                {showEmployerContributions ? 'Hide' : 'Show'}
                            </ToggleButton>
                        </CategoryHeader>
                        {showEmployerContributions && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Percentage</TableCell>
                                        <TableCell>Amount (PM)</TableCell>
                                        <TableCell>Amount (PA)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {employerContributions.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>{item.value}%</TableCell>
                                            <TableCell>₹{item.amountPM.toLocaleString()}</TableCell>
                                            <TableCell>₹{item.amountPA.toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow style={{ backgroundColor: "#d0f0c0", fontWeight: "bold" }}>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">Cost to Company (CTC)</Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <Typography fontWeight="bold">₹{costToCompanyPM.toLocaleString()}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography fontWeight="bold">₹{costToCompanyPA.toLocaleString()}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                    </CategoryCard>

                    <CategoryCard>
                        <CategoryHeader onClick={() => setShowDeductions(!showDeductions)}>
                            <Typography variant="h6">Deductions</Typography>
                            <ToggleButton size="small">
                                {showDeductions ? 'Hide' : 'Show'}
                            </ToggleButton>
                        </CategoryHeader>
                        {showDeductions && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Percentage</TableCell>
                                        <TableCell>Amount (PM)</TableCell>
                                        <TableCell>Amount (PA)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deductions.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>{item.value}%</TableCell>
                                            <TableCell>₹{item.amountPM.toLocaleString()}</TableCell>
                                            <TableCell>₹{item.amountPA.toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow style={{ backgroundColor: "#ffc0cb", fontWeight: "bold" }}>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">Approx. Net Take Home</Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <Typography fontWeight="bold">₹{netHomePA.toLocaleString()}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography fontWeight="bold">₹{netHomePA.toLocaleString()}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                    </CategoryCard>
                </>
            )}

            {loading && <Loader />}
        </Container>
    );
};

export default SalaryDetails;

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
`;

const CategoryCard = styled(Card)`
    margin-top: 20px;
    padding: 20px;
`;

const CategoryHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
`;

const ToggleButton = styled(Button)`
    background-color: #1976D2 !important;
    color: white !important;
    font-weight: bold !important;
    &:hover {
        background-color: #1565C0 !important;
    }
`;
