import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Box, Button, Card, CardContent, Divider, Grid, LinearProgress, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { CheckCircle, Info } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { eachDayOfInterval, endOfMonth, endOfWeek, format, isSaturday, isSunday, normalizeDate, startOfMonth, startOfWeek } from '../../../utils/CustomPackages/dateUtils';
import { selectToken } from '../../../Redux/selector';
import TimePeriod from '../../../Components/UiUxComponents/CustomComponents/TimePeriod';
import { addAttendance, getCurrentDayAttendanceStatus, getSpecificUserAttendance, generateAverageAttendanceStats } from '../../../utils/Services/attendanceServices';
import { getShift } from '../../../utils/Services/shiftServices';

function Attendance() {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const [currentDate, setCurrentDate] = useState(format(new Date(), 'EEE dd, MMM yyyy'));
  const [timeZone, setTimeZone] = useState(() =>
    new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
      .formatToParts(new Date())
      .find(part => part.type === 'timeZoneName')?.value || ''
  );
  const [selectedButton, setSelectedButton] = useState('Office Clock-in');
  const [currentAttendanceStatus, setCurrentAttendanceStatus] = useState([]);
  const [userAttendanceData, setUserAttendanceData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [startDate, setStartDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [endDate, setEndDate] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }));
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const token = useSelector(selectToken);
  const { userId } = useParams();

  // To Get Current Day Attendance Status
  const fetchCurrentDayAttendanceStatus = async () => {
    setLoading(true);
    try {
      const currentDateTime = new Date().toISOString();
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const data = { currentDateTime, timeZone };

      const response = await getCurrentDayAttendanceStatus(token, userId, data);
      const todayRecords = response?.data?.data;
      if (todayRecords) {
        setCurrentAttendanceStatus(todayRecords);
      } else {
        console.log("Today Records is empty")
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user attendance',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCurrentDayAttendanceStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To get user shifts Data
  const fetchShiftDetails = async () => {
    setLoading(true);
    try {
      const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 })
      const weekEnd = endOfWeek(new Date(), { weekStartsOn: 1 })
      const formattedData = {
        weekStart,
        weekEnd
      };
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const data = { formattedData, timeZone };
      const response = await getShift(token, userId, data);
      console.log("res", response?.data)
      setShiftData(response?.data || [])
    } catch (err) {
      console.error('Error fetching user shift:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user shift',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchShiftDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To get the default user attendance data
  const fetchUserAttendanceData = async () => {
    setLoading(true);
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const data = { startDate, endDate, timeZone };

      const response = await getSpecificUserAttendance(token, userId, data);
      setUserAttendanceData(response?.data?.attendance || [])
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user attendance',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserAttendanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // To get Average Attendance Stats
  const fetchUserAverageAttendanceStats = async () => {
    setLoading(true);
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
      const weekEnd = endOfWeek(new Date(), { weekStartsOn: 1 });
      const monthStartDate = startOfMonth(new Date());
      const monthEndDate = endOfMonth(new Date());

      const formattedData = {
        startDate: weekStart,
        endDate: weekEnd,
        monthStart: monthStartDate,
        monthEnd: monthEndDate,
        timeZone: timeZone
      };
      const response = await generateAverageAttendanceStats(token, userId, formattedData);
      setWeeklyData(response?.data?.weeklyData)
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user attendance',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserAverageAttendanceStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update current time and date every second
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const newTime = now.toLocaleTimeString();
      const newDate = format(now, 'EEE dd, MMM yyyy');
      const newTimeZone = new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
        .formatToParts(now)
        .find(part => part.type === 'timeZoneName')?.value || '';

      if (newTime !== currentTime) {
        setCurrentTime(newTime);
      }
      if (newDate !== currentDate) {
        setCurrentDate(newDate);
      }
      if (newTimeZone !== timeZone) {
        setTimeZone(newTimeZone);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [currentTime, currentDate, timeZone]);

  // To Generate Weekly Days
  const generateWeeklyDays = () => {
    const now = new Date();
    const start = startOfWeek(now, { weekStartsOn: 1 });
    const end = endOfWeek(now, { weekStartsOn: 1 });
    return eachDayOfInterval(start, end);
  };

  // Handle date change 
  const handleDateRangeChange = (start, end) => {
    if (start) setStartDate(start);
    if (end) setEndDate(end);
  };

  // To Generate Date for Table
  const generateCustomDatesForTable = () => {
    const start = startDate
    const end = endDate
    return eachDayOfInterval(start, end, "weekdates");
  };

  // To Display Data in table
  const generateRecords = () => {
    const dates = generateCustomDatesForTable();
    return dates.map(date => {
      const isWeekend = isSaturday(date) || isSunday(date);
      const record = userAttendanceData.find(r => {
        const clockInDate = normalizeDate(new Date(r.clockInTime));
        const comparisonDate = normalizeDate(new Date(date));
        return format(clockInDate, 'yyyy-MM-dd') === format(comparisonDate, 'yyyy-MM-dd');
      });
      const shiftRecord = shiftData.find(s =>
        format(normalizeDate(new Date(s.date)), 'yyyy-MM-dd') === format(normalizeDate(date), 'yyyy-MM-dd')
      );
      let tag = null;
      if (shiftRecord) {
        if (shiftRecord.shiftType === 'weekOff') {
          tag = "W-Off";
        } else if (shiftRecord.shiftType === 'leave') {
          tag = "Leave";
        }
      }
      return { date, record, isWeekend, tag };
    });
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mouse-over-popover' : undefined;

  const filteredRecords = generateRecords();
  const attendanceStatus = currentAttendanceStatus.length > 0 ? currentAttendanceStatus[0].attendanceStatus : 'Clock-in';

  // handle clockout button
  const handleClockInOut = useCallback(async () => {
    const currentDateTime = new Date().toISOString();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const getLocation = () => {
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          setLoading(true)
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLoading(false);
              resolve(position.coords)
            },
            () => {
              setLoading(false);
              resolve(null)
            }
          );
        } else {
          resolve(null);
        }
      });
    };
    try {
      let arrival;
      console.log("new Data", new Date())
      const todayDate = new Date().toLocaleDateString('en-CA');
      console.log("todayDate", todayDate)
      console.log("shiftData", shiftData)
      const todayShift = shiftData.find(
        (shift) => new Date(shift.date).toISOString().split('T')[0] === todayDate
      );
      console.log("todayShift", todayShift)

      // if (!todayShift) {
      //   Swal.fire({
      //     icon: 'warning',
      //     title: 'No Shift Data',
      //     text: 'Please contact your administrator.',
      //     confirmButtonColor: '#1B4965',
      //   });
      //   return;
      // }

      if (['none', 'leave', 'weekOff'].includes(todayShift?.shiftType)) {
        Swal.fire({
          icon: 'warning',
          title: `Today ${todayShift.shiftType === 'none' ? 'Shift Not Assigned' : todayShift.shiftType}`,
          text: 'Please contact your administrator.',
          confirmButtonColor: '#1B4965',
        });
        return;
      }
      if (todayShift.shiftType === 'shift' || todayShift.shiftType === 'default') {
        const startTime = todayShift.shiftData
          ? todayShift.shiftData.startTime
          : todayShift.startTime;
        console.log("startTime", startTime)
        const shiftStart = new Date(`${todayDate}T${startTime}:00`);

        console.log("shiftStart", shiftStart)

        const endTime = todayShift.shiftData
          ? todayShift.shiftData.endTime
          : todayShift.endTime;
        console.log("endTime", endTime)
        const shiftEnd = new Date(`${todayDate}T${endTime}:00`);

        console.log("shiftEnd", shiftEnd)

        const currentTime = new Date();

        console.log("currentTime", currentTime)

        const earlyClockinBuffer = new Date(shiftStart.getTime() - 15 * 60 * 1000);
        const lateClockinBuffer = new Date(shiftStart.getTime() + 15 * 60 * 1000);

        console.log("earlyClockinBuffer", earlyClockinBuffer)
        console.log("lateClockinBuffer", lateClockinBuffer)

        const earlyClockoutBuffer = new Date(shiftEnd.getTime() - 15 * 60 * 1000);

        console.log("earlyClockoutBuffer", earlyClockoutBuffer)

        if (attendanceStatus === 'Clock-in') {

          console.log("Clockin")

          const formatTimeDifference = (difference) => {
            const hours = Math.floor(difference / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);
            return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
          };

          if (currentTime < earlyClockinBuffer) {

            console.log('Early Clockin')

            const timeDifference = earlyClockinBuffer - currentTime;

            const confirmation = await Swal.fire({
              icon: 'warning',
              title: 'Early Clock-in',
              text: 'You are attempting to clock in before the shift starts. Do you want to proceed?',
              showCancelButton: true,
              confirmButtonColor: '#1B4965',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Clock in!',
            });

            if (!confirmation.isConfirmed) {
              return;
            }

            arrival = `🌅 ${formatTimeDifference(timeDifference)} Early`;

            console.log('Early Clockin Arrival', arrival)

          } else if (currentTime <= lateClockinBuffer) {

            console.log("On Time")

            arrival = 'On time';

            console.log("On Time Arrival", arrival)

          } else if (currentTime <= shiftEnd) {

            console.log("Late Clockin")

            const timeDifference = currentTime - lateClockinBuffer;

            const result = await Swal.fire({
              icon: 'warning',
              title: 'Late Clock-in',
              text: 'You are clocking in late. Do you want to continue?',
              showCancelButton: true,
              confirmButtonColor: '#1B4965',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Continue',
              cancelButtonText: 'No, Exit',
            });

            if (result.isConfirmed) {
              arrival = `${formatTimeDifference(timeDifference)} Late 🐌`;

              console.log("Late Clockin Arrival", arrival)
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              return;
            }
          }
        }

        if (attendanceStatus === 'Clock-out') {

          console.log("Clockout")

          if (currentTime < earlyClockoutBuffer) {

            console.log('Early Clockout')
            const confirmation = await Swal.fire({
              icon: 'warning',
              title: 'Early Clock-out',
              text: 'You are attempting to clock out before the shift ends. Do you want to proceed?',
              showCancelButton: true,
              confirmButtonColor: '#1B4965',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Clock out!',
            });
            if (!confirmation.isConfirmed) {
              return;
            }
          } else {
            const confirmation = await Swal.fire({
              icon: 'question',
              title: 'Clock-out Confirmation',
              text: 'Do you want to clock out now?',
              showCancelButton: true,
              confirmButtonColor: '#1B4965',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Clock out!',
            });
            if (!confirmation.isConfirmed) {
              return;
            }
          }
        }
      }
      setLoading(true);
      const coords = await getLocation();
      setLoading(false);
      const latitude = coords?.latitude || 'N/A';
      const longitude = coords?.longitude || 'N/A';
      const data = { attendanceStatus, timeZone, time: currentDateTime, place: selectedButton, location: { latitude, longitude }, arrival };
      setLoading(true);
      console.log("data", data)
      await addAttendance(token, userId, data);
      Swal.fire({
        icon: 'success',
        title: `${attendanceStatus} Success`,
        confirmButtonColor: '#1B4965',
      });
    } catch (err) {
      if (err?.response?.status === 404) {
        Swal.fire({
          icon: 'warning',
          title: 'Shift Not Assigned',
          text: 'Please contact your administrator.',
          confirmButtonColor: '#1B4965',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: err?.response?.data?.message || 'Failed to Save Attendance',
          confirmButtonColor: '#1B4965',
        });
      }
    } finally {
      setLoading(false);
      fetchCurrentDayAttendanceStatus();
      fetchShiftDetails();
      fetchUserAttendanceData();
      fetchUserAverageAttendanceStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftData, attendanceStatus])

  function parseDate(dateString) {
    return new Date(dateString);
  }

  return (
    <span>
      <Box sx={{ padding: 2, background: '#f1f1f1', maxHeight: '85vh', overflow: 'auto' }}>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Typography variant="h6">Timings</Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2, marginTop: 1 }}>
                  {generateWeeklyDays().map((date, index) => {
                    const dayName = format(date, 'EEE').charAt(0);
                    const isSelected = selectedDay.toDateString() === date.toDateString();
                    return (
                      <Button key={index} variant={isSelected ? 'contained' : 'outlined'} onClick={() => setSelectedDay(date)}
                        sx={{ minWidth: 34, height: 36, borderRadius: '50%', borderColor: isSelected ? 'transparent' : '#1B4965', backgroundColor: isSelected ? '#1B4965' : '#F1F1F1', color: isSelected ? 'white' : '#1B4965', '&:hover': { backgroundColor: '#1B4965', color: 'white' } }}
                      >
                        {dayName}
                      </Button>
                    );
                  })}
                </Box>
                {(() => {
                  const shiftRecord = shiftData.find(record => {
                    const recordDate = new Date(record.date);
                    return (
                      recordDate.getFullYear() === selectedDay.getFullYear() &&
                      recordDate.getMonth() === selectedDay.getMonth() &&
                      recordDate.getDate() === selectedDay.getDate()
                    );
                  });

                  let shiftTimingText = "Shift Not Added";

                  const todayRecords = weeklyData.filter(record => {
                    const recordDate = parseDate(record.clockInTime);
                    const selectedDate = new Date(selectedDay);
                    return (
                      recordDate.getFullYear() === selectedDate.getFullYear() &&
                      recordDate.getMonth() === selectedDate.getMonth() &&
                      recordDate.getDate() === selectedDate.getDate()
                    );
                  });

                  let clockInTime = "00:00";
                  let clockOutTime = "00:00";
                  let duration = "00:00";

                  if (todayRecords.length > 0) {
                    const latestRecord = todayRecords[todayRecords.length - 1];

                    clockInTime = latestRecord && latestRecord.clockInTime
                      ? format(new Date(latestRecord.clockInTime), 'hh:mm a')
                      : '-';

                    if (latestRecord?.clockOutTime) {

                      clockOutTime = latestRecord && latestRecord.clockOutTime
                        ? format(new Date(latestRecord.clockOutTime), 'hh:mm a')
                        : '-';

                      duration = latestRecord?.grossHours;

                    }
                  }

                  let progressPercentage = "0";

                  if (shiftRecord) {
                    if (shiftRecord?.shiftType === "shift" && shiftRecord?.shiftData) {
                      const startTime = shiftRecord?.shiftData?.startTime;
                      const endTime = shiftRecord?.shiftData?.endTime;
                      shiftTimingText = `Shift Timings :- (${startTime} - ${endTime})`;
                    } else if (shiftRecord.shiftType === "weekOff") {
                      shiftTimingText = "Week Off";
                    } else if (shiftRecord.shiftType === "leave") {
                      shiftTimingText = "Leave";
                    } else if (shiftRecord.shiftType === "default") {
                      const startTime = shiftRecord?.startTime;
                      const endTime = shiftRecord?.endTime;
                      shiftTimingText = `Shift Timings :- (${startTime} - ${endTime})`;
                    }

                    const parseTime = (timeStr) => {
                      if (!timeStr) return "";
                      const [hours, minutes] = timeStr.split(":").map(Number);
                      const date = new Date();
                      date.setHours(hours, minutes, 0, 0);
                      return date;
                    };

                    const shiftStartTime = shiftRecord.shiftData
                      ? shiftRecord.shiftData.startTime
                      : shiftRecord.startTime;

                    const shiftEndTime = shiftRecord.shiftData
                      ? shiftRecord.shiftData.endTime
                      : shiftRecord.endTime;

                    const parsedStartTime = parseTime(shiftStartTime);
                    const parsedEndTime = parseTime(shiftEndTime);

                    const currentTime = new Date();
                    let effectiveEndTime;

                    const latestRecord = todayRecords[todayRecords.length - 1];
                    if (latestRecord?.clockOutTime) {
                      effectiveEndTime = latestRecord.clockOutTime
                    } else {
                      effectiveEndTime = currentTime;
                    }

                    const totalShiftMilliseconds = parsedEndTime - parsedStartTime;

                    const effectiveEndTimeDate = new Date(effectiveEndTime);

                    const completedMilliseconds = effectiveEndTimeDate - parsedStartTime;

                    const totalShiftHours = totalShiftMilliseconds / (1000 * 60 * 60);
                    const completedHours = completedMilliseconds / (1000 * 60 * 60);

                    if (latestRecord?.clockInTime) {
                      progressPercentage = Math.min((completedHours / totalShiftHours) * 100, 100);
                    }
                  }

                  return (
                    <Box>
                      <Typography variant="body1">Today ({clockInTime} - {clockOutTime})</Typography>
                      <Box sx={{ marginY: 1 }}>
                        <LinearProgress variant="determinate"
                          value={progressPercentage}
                          sx={{
                            height: 8,
                            borderRadius: 5,
                            '& .MuiLinearProgress-bar': {
                              backgroundColor:
                                progressPercentage >= 75 ? '#051925' :
                                  progressPercentage >= 50 ? '#1B4965' : 'red',
                            },
                          }}
                        />
                        <Typography variant="body2">{`${Math.round(progressPercentage)}% completed`}</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2">Duration: {duration}</Typography>
                        {/* <Typography variant="body2">Break: 1:00 hr</Typography> */}
                      </Box>
                      <Divider sx={{ marginY: 2 }} />
                      <Typography variant="body2">{shiftTimingText}</Typography>
                    </Box>
                  );
                })()}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Typography variant="h6">Actions</Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div>
                    <Typography variant="h4">{currentTime} ({timeZone})</Typography>
                    <Typography variant="h6">{currentDate}</Typography>
                  </div>
                  <Button variant="contained" color="primary" onClick={handleClockInOut}
                    sx={{
                      marginY: 2,
                      backgroundImage:
                        attendanceStatus === 'Clock-in'
                          ? 'linear-gradient(45deg, #1B4965, #1E6091)'
                          : 'linear-gradient(45deg, #B42626, #D9534F)',
                      '&:hover': {
                        backgroundImage:
                          attendanceStatus === 'Clock-in'
                            ? 'linear-gradient(45deg, #1B4965, #1E6091)'
                            : 'linear-gradient(45deg, #B42626, #D9534F)',
                      },
                      padding: '16px 24px',
                      fontSize: '18px',
                      height: '60px',
                      minWidth: '200px',
                    }}>{attendanceStatus}
                  </Button>
                </div>
                <div>
                  <Button
                    variant={selectedButton === 'Office Clock-in' ? 'outlined' : 'text'}
                    sx={{ color: '#1B4965', borderColor: '#1B4965', backgroundColor: selectedButton !== 'Office Clock-in' ? 'white' : '#F1F1F1' }}
                    onClick={() => setSelectedButton('Office Clock-in')}
                  >Office Clock-in
                  </Button>
                  <Button
                    variant={selectedButton === 'Work From Home' ? 'outlined' : 'text'}
                    sx={{ color: '#1B4965', borderColor: '#1B4965', backgroundColor: selectedButton !== 'Work From Home' ? 'white' : '#F1F1F1' }}
                    onClick={() => setSelectedButton('Work From Home')}
                  >Work From Home
                  </Button>
                </div>
                <Divider sx={{ marginY: 2 }} />
                <Typography variant="h7" sx={{ color: '#1B4965' }}>Attendance Policy</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Logs
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TimePeriod startDate={startDate} endDate={endDate} onDateRangeChange={handleDateRangeChange} />
                  </Box>
                </Box>
                <Divider sx={{ marginY: 2 }} />
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Clock-In</TableCell>
                        <TableCell>Clock-Out</TableCell>
                        <TableCell>Location</TableCell>
                        {/* <TableCell>Effective Hours</TableCell> */}
                        <TableCell>Gross Hours</TableCell>
                        <TableCell>Arrival</TableCell>
                        <TableCell>Log</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRecords?.map((item, index) => {
                        const clockIn = item?.record && item.record.clockInTime
                          ? format(new Date(item.record.clockInTime), 'hh:mm a')
                          : '-';
                        let clockOut = item?.record && item.record.clockOutTime
                          ? format(new Date(item.record.clockOutTime), 'hh:mm a')
                          : '-';
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="body2">{format(item.date, 'MMM dd, EEE')}</Typography>
                              {item.tag && (
                                <Button variant="contained" size="small"
                                  sx={{
                                    marginRight: 0.5,
                                    marginTop: 0.5,
                                    backgroundColor: '#FF6347',
                                    '&:hover': {
                                      backgroundColor: '#FF6347',
                                    },
                                  }}
                                >
                                  {item.tag}
                                </Button>
                              )}
                              {item.record && item.record.place && !item.tag && (
                                <Button variant="contained" size="small"
                                  sx={{
                                    marginRight: 0.5,
                                    marginTop: 0.5,
                                    backgroundColor: '#1B4965',
                                    '&:hover': {
                                      backgroundColor: '#1B4965',
                                    },
                                  }}
                                >
                                  {item.record.place === 'Office Clock-in' ? 'Office' : item.record.place === 'Work From Home' ? 'WFH' : 'Other'}
                                </Button>
                              )}
                            </TableCell>
                            <TableCell>{clockIn}</TableCell>
                            <TableCell>{clockOut}</TableCell>
                            <TableCell>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {item?.record?.location ? (
                                  <>
                                    <LocationOnIcon onMouseEnter={handlePopoverOpen} sx={{ marginRight: 1, color: '#1B4965' }} />
                                    <Popover id={id} open={open} anchorEl={anchorEl} onClose={handlePopoverClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} sx={{ boxShadow: 3, borderRadius: 1, }} disableRestoreFocus>
                                      <Box sx={{ p: 0, width: 300, overflow: 'hidden', borderRadius: 1 }} onMouseLeave={handlePopoverClose}>
                                        <MapContainer center={[item.record.location.latitude, item.record.location.longitude]} zoom={15} style={{ width: '100%', height: '200px', borderRadius: '8px' }}>
                                          <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                                          <Marker
                                            position={[item.record.location.latitude, item.record.location.longitude]}
                                            icon={new L.Icon({
                                              iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
                                              iconSize: [25, 41],
                                              iconAnchor: [12, 41],
                                              popupAnchor: [1, -34],
                                            })}
                                          >
                                            <Popup>
                                              Latitude: {item.record.location.latitude}<br />
                                              Longitude: {item.record.location.longitude}
                                            </Popup>
                                          </Marker>
                                        </MapContainer>
                                      </Box>
                                    </Popover>
                                  </>
                                ) : (
                                  <span>-</span>
                                )}
                              </div>
                            </TableCell>
                            {/* <TableCell>{item.record && item.record.effectiveHours ? `${item.record.effectiveHours} hrs` : '-'}</TableCell> */}
                            <TableCell>{item.record && item.record.grossHours ? `${item.record.grossHours} hrs` : '-'}</TableCell>
                            <TableCell>{item.record && item.record.arrival ? `${item.record.arrival}` : '-'}</TableCell>
                            <TableCell>
                              {item.record && item.record.clockInTime && !item.record.clockOutTime ? (
                                <Info color="warning" />
                              ) : (
                                item.record ? <CheckCircle color="success" /> : '-'
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {(loading) && (<Loader />)}
    </span >
  )
}

export default Attendance