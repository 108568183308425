import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import './CustomCalendar.css';
import Loader from '../../Loader/Loader';
import { selectToken, selectUser } from '../../../../Redux/selector';
import { getYearlyCalendarEvent } from '../../../../utils/Services/subscriberConfigurationServices/yearlyCalendarServices';
import Swal from 'sweetalert2';

const CustomCalendar = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);

  const subscriberId = user?.companyId?._id;

  const fetchEvents = async () => {
    try {
      setLoading(true)
      const response = await getYearlyCalendarEvent(token, subscriberId);
      const formattedEvents = response.data.map(event => {
        const formattedDate = new Date(event.date).toISOString().split('T')[0];
        return { ...event, date: formattedDate };
      });
      setEvents(formattedEvents);

    } catch (err) {
      console.error("Failed to fetch events", err);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error. Please try again later.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div style={{ backgroundColor: 'white', borderRadius: '12px', padding: '20px' }}>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        // headerToolbar={{
        //   start: 'prev,next',
        //   center: 'title',
        //   end: 'dayGridMonth,timeGridWeek,timeGridDay',
        // }}
        headerToolbar={{
          left: 'prev',
          right: 'next',
          center: 'title',
        }}
        height="600px"
        contentHeight="auto"
        scrollTime="08:00:00"
        dayMaxEvents={true}
        events={events}
        dayCellClassNames={function (dateInfo) {
          const dayOfWeek = dateInfo.date.getDay();
          if (dayOfWeek === 6) {
            return 'saturday-cell';
          } else if (dayOfWeek === 0) {
            return 'sunday-cell';
          } else {
            return 'weekday-cell'
          }
        }}
      />
      <style>
        {`
                    .saturday-cell {
                        background-color: #fff0f0; /* Light blue for Saturday */
                    }
                    .sunday-cell {
                        background-color: #fff0f0; /* Light pink for Sunday */
                    }
                    .weekday-cell {
                        background-color: #e0f7fa; /* Light cyan for Monday to Friday */
                    }
                `}
      </style>
      {loading && <Loader />}
    </div>
  );
};

export default CustomCalendar;
