import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Swal from 'sweetalert2';
import { Box, Button, ButtonGroup, Menu, MenuItem, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Loader from "../../../../../UiUxComponents/Loader/Loader";
import { selectToken } from "../../../../../../Redux/selector";
import { downloadUsersData } from "../../../../../../utils/Services/importexportServices";

const ExportUsers = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [fileFormat, setFileFormat] = useState("xlsx");
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { subscriberId } = useParams();


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (format) => {
        if (format) setFileFormat(format);
        setAnchorEl(null);
    };

    const exportToFile = async () => {
        setLoading(true);

        try {
            const response = await downloadUsersData(token, subscriberId);

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `users_data.xlsx`;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
            Swal.fire("Error", "An error occurred while downloading the file", "error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item>
                    <ButtonGroup variant="contained" color="primary">
                        <Button onClick={exportToFile}>
                            Export ({fileFormat.toUpperCase()})
                        </Button>
                        <Button onClick={handleClick} size="small">
                            <ArrowDropDownIcon />
                        </Button>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose(null)}>
                            <MenuItem onClick={() => handleClose("xlsx")}>Excel (.xlsx)</MenuItem>
                            <MenuItem onClick={() => handleClose("csv")}>CSV (.csv)</MenuItem>
                        </Menu>
                    </ButtonGroup>
                </Grid>
            </Grid>

            {loading && <Loader />}
        </Box>
    );
};

export default ExportUsers;
