import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, Typography, Box, IconButton, Popover, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { AddCircle, CalendarToday, EventBusy, FlightTakeoff, Weekend } from '@mui/icons-material';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { getAllShift, getAssignedShift, assignShift } from '../../../../utils/Services/shiftServices';
import { getAllUsersDetails } from '../../../../utils/Services/userServices';
import { addDays, format, startOfWeek } from '../../../../utils/CustomPackages/dateUtils';

const ShiftTable = ({ currentWeek }) => {
  const [usersData, setUsersData] = useState([]);
  const [assignedShiftData, setAssignedShiftData] = useState({});
  const [createdShiftData, setCreatedShiftData] = useState([]);
  const [usersShiftInformation, setUsersShiftInformation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [secondPopoverAnchorEl, setSecondPopoverAnchorEl] = useState(null);
  const [selectedDateKey, setSelectedDateKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();

  const startDate = startOfWeek(currentWeek, { weekStartsOn: 1 });
  const weekDates = Array.from({ length: 7 }, (_, i) => addDays(startDate, i));
  const isPopoverOpen = Boolean(anchorEl);
  const isSecondPopoverOpen = Boolean(secondPopoverAnchorEl);

  useEffect(() => {
    fetchUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchAssignedShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeek]);
  useEffect(() => {
    fetchCreatedShiftDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To Get the Week range
  const getWeekRange = (date) => {
    const startOfWeek = new Date(date);
    const endOfWeek = new Date(date);

    const day = date.getDay();
    const diffToMonday = day === 0 ? -6 : 1 - day;

    startOfWeek.setDate(date.getDate() - diffToMonday);
    startOfWeek.setHours(0, 0, 0, 0);

    endOfWeek.setDate(date.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    return {
      firstDay: new Date(startOfWeek.getTime() - startOfWeek.getTimezoneOffset() * 60000).toISOString(),
      lastDay: new Date(endOfWeek.getTime() - endOfWeek.getTimezoneOffset() * 60000).toISOString(),
    };
  }

  // To Set Assigned Data
  const transformData = (data) => {
    return data.reduce((acc, item) => {
      const key = `${item?.userId}-${item?.date.split('T')[0]}`;

      if (item?.shiftType === "weekOff") {
        acc.weekOff[key] = true;
      } else if (item?.shiftType === "leave") {
        acc.leave[key] = true;
      } else {
        acc.assignedShift[key] = item?.shiftData;
      }

      return acc;
    }, { weekOff: {}, leave: {}, assignedShift: {} });
  };

  // Fetch user details
  const fetchUserDetail = async () => {
    setLoading(true);
    try {
      const response = await getAllUsersDetails(token, subscriberId);
      const data = response?.data?.data || [];
      const activeUsers = data.filter(user => user.isActive);
      const sortedData = activeUsers.sort((a, b) => a.fullName.localeCompare(b.fullName));
      setUsersData(sortedData);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error. Please try again later.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch assigned shifts
  const fetchAssignedShifts = async () => {
    setLoading(true);
    try {
      let { firstDay, lastDay } = getWeekRange(currentWeek);

      let data = { firstDay, lastDay }
      const response = await getAssignedShift(token, subscriberId, data);
      const transformedData = transformData(response?.data?.assignedShifts);
      setAssignedShiftData(transformedData);
      setUsersShiftInformation(response?.data?.userOfficeInformation)
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error. Please try again later.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch created shiftsdetails
  const fetchCreatedShiftDetail = async () => {
    setLoading(true);
    try {
      const response = await getAllShift(token, subscriberId);
      if (response.status === 404) {
        setCreatedShiftData([]);
      } else {
        setCreatedShiftData(response?.data);
      }
    } catch (error) {
      console.error('Error fetching shifts:', error);
      if (error.response?.status !== 404) {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Failed to fetch Shifts',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // To Open Popover
  const handlePopoverOpen = (event, dateKey) => {
    setAnchorEl(event.currentTarget);
    setSelectedDateKey(dateKey);
  };

  // To Close Popover
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedDateKey(null);
  };

  // To Open Second Popover
  const handleSecondPopoverClose = async (shift) => {
    if (shift) {
      const [userId, year, month, date] = selectedDateKey.split('-');
      const formattedDate = `${month}-${date}-${year}`;
      const shiftData = {
        date: formattedDate,
        type: 'shift',
        shift: shift,
      };

      await assignShift(token, subscriberId, userId, shiftData);

      const updatedShiftData = {
        ...assignedShiftData,
        weekOff: { ...assignedShiftData?.weekOff },
        leave: { ...assignedShiftData?.leave },
        assignedShift: { ...assignedShiftData?.assignedShift }
      };
      delete updatedShiftData.weekOff[selectedDateKey];
      delete updatedShiftData.leave[selectedDateKey];

      const updatedShiftData2 = {
        ...updatedShiftData,
        assignedShift: {
          ...updatedShiftData?.assignedShift,
          [`${selectedDateKey}`]: shift,
        },
      }
      setAssignedShiftData(updatedShiftData2);
    }
    setSecondPopoverAnchorEl(null);
    handlePopoverClose();
  };

  // Handle Menu Item Click
  const handleOptionClick = (option, event) => {
    if (option === 'Add Shift') {
      setSecondPopoverAnchorEl(event.currentTarget);
    } else if (option === 'weekOff') {
      sendWeekOffData();

      const updatedData = {
        ...assignedShiftData,
        weekOff: { ...assignedShiftData?.weekOff },
        leave: { ...assignedShiftData?.leave },
        assignedShift: { ...assignedShiftData?.assignedShift }
      };

      delete updatedData.leave[selectedDateKey];
      delete updatedData.assignedShift[selectedDateKey];

      updatedData.weekOff[selectedDateKey] = true;

      setAssignedShiftData(updatedData);
      handlePopoverClose();
    }
    else if (option === 'leave') {
      sendLeaveData();

      const updatedData = {
        ...assignedShiftData,
        weekOff: { ...assignedShiftData?.weekOff },
        leave: { ...assignedShiftData?.leave },
        assignedShift: { ...assignedShiftData?.assignedShift }
      };

      delete updatedData.weekOff[selectedDateKey];
      delete updatedData.assignedShift[selectedDateKey];

      updatedData.leave[selectedDateKey] = true;
      setAssignedShiftData(updatedData);
      handlePopoverClose();
    }
  };

  // To Send Leave Data
  const sendLeaveData = async () => {
    const [userId, year, month, date] = selectedDateKey.split('-');
    const formattedDate = `${month}-${date}-${year}`;
    const leaveData = {
      date: formattedDate,
      type: 'leave',
    };
    await assignShift(token, subscriberId, userId, leaveData);
  };

  // To Send Weekoff Data
  const sendWeekOffData = async () => {
    const [userId, year, month, date] = selectedDateKey.split('-');
    const formattedDate = `${month}-${date}-${year}`;

    const weekOffData = {
      type: 'weekOff',
      date: formattedDate,
    };

    try {
      await assignShift(token, subscriberId, userId, weekOffData);
    } catch (error) {
      console.error('Error sending week-off data:', error);
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ border: '1px solid #ddd' }}>
        <Table sx={{ minWidth: 650, borderCollapse: 'separate' }} aria-label="shift table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '130px', fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ddd', backgroundColor: '#f9f9f9' }}>
                <Typography style={{ fontSize: '16px' }}>Employee Name</Typography>
              </TableCell>
              {weekDates.map((date) => (
                <TableCell key={date} align='center' sx={{ borderLeft: '1px solid #ddd', verticalAlign: 'middle', border: '1px solid #ddd', backgroundColor: '#f9f9f9' }}>
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Box sx={{ marginRight: '5px', }}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '40px', color: '#333' }}>{format(date, 'dd')}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography sx={{ fontSize: '15px', color: '#666', fontWeight: 'bold' }}>{format(date, 'EEE')}</Typography>
                      <Typography sx={{ fontSize: '14px', color: '#999', }}>{format(date, 'MMM')}</Typography>
                    </Box>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(assignedShiftData?.weekOff && assignedShiftData?.leave && assignedShiftData?.assignedShift) && usersData?.map((user) => (
              < TableRow key={user?._id}>
                <TableCell sx={{ border: '1px solid #ddd', backgroundColor: '#f9f9f9' }}>
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ marginRight: '8px' }}>{user?.firstName[0]}{user?.lastName[0]}</Avatar>
                    {user?.fullName}
                  </Box>
                </TableCell>
                {weekDates?.map((date) => {
                  const dateKey = `${user?._id}-${format(date, 'yyyy-MM-dd')}`;
                  const cellStyles = { textAlign: 'center', padding: '5px', border: '1px solid #ddd', borderRadius: '4px', position: 'relative' };
                  let userData = usersShiftInformation.filter(item => item._id === user._id);
                  if (userData.length > 0) {
                    userData = userData[0];
                  } else {
                    userData = {}
                  }
                  const innerBoxStyles = {
                    backgroundColor: assignedShiftData?.weekOff[dateKey]
                      ? '#F5F5F5'
                      : assignedShiftData?.leave[dateKey]
                        ? '#FFE0B2'
                        : assignedShiftData?.assignedShift[dateKey]
                          ? '#C8E6C9'
                          : userData?._id
                            ? '#C8E6C9'
                            : 'transparent',
                    padding: '8px',
                    borderRadius: '4px',
                  };
                  return (
                    <TableCell key={date} sx={cellStyles}>
                      <Box sx={innerBoxStyles}>
                        {assignedShiftData?.weekOff[dateKey] && (
                          <Box sx={{ cursor: 'pointer' }} onClick={(event) => handlePopoverOpen(event, dateKey)}>
                            <Weekend sx={{ fontSize: 20, color: '#1B4965', marginRight: '8px' }} />
                            <Typography variant="body2">Week off</Typography>
                          </Box>
                        )}
                        {assignedShiftData?.leave[dateKey] && (
                          <Box sx={{ cursor: 'pointer' }} onClick={(event) => handlePopoverOpen(event, dateKey)}>
                            <FlightTakeoff sx={{ fontSize: 20, color: '#red', marginRight: '8px' }} />
                            <Typography variant="body2">On leave</Typography>
                          </Box>
                        )}
                        {assignedShiftData?.assignedShift[dateKey] && (
                          <Box sx={{ cursor: 'pointer' }} onClick={(event) => handlePopoverOpen(event, dateKey)}>
                            <Typography variant="body2">{assignedShiftData?.assignedShift[dateKey]?.shiftTitle}</Typography>
                            <Typography variant="subtitle2" sx={{ margin: 0 }}>
                              {assignedShiftData?.assignedShift[dateKey]?.startTime} - {assignedShiftData?.assignedShift[dateKey]?.endTime}
                            </Typography>
                            <Typography variant="body2" sx={{ margin: 0 }}>
                              {assignedShiftData?.assignedShift[dateKey]?.duration} hrs
                            </Typography>
                          </Box>
                        )}
                        {!assignedShiftData?.weekOff[dateKey] &&
                          !assignedShiftData?.leave[dateKey] &&
                          !assignedShiftData?.assignedShift[dateKey] &&
                          !userData?._id &&
                          (
                            <IconButton onClick={(event) => handlePopoverOpen(event, dateKey)}>
                              <AddCircle color="primary" />
                            </IconButton>
                          )
                        }
                        {(!assignedShiftData?.weekOff[dateKey] &&
                          !assignedShiftData?.leave[dateKey] &&
                          !assignedShiftData?.assignedShift[dateKey]) &&
                          userData?._id &&
                          (
                            <Box sx={{ cursor: 'pointer' }} onClick={(event) => handlePopoverOpen(event, dateKey)}>
                              <Typography variant="body2">{userData?.officeInformation?.shiftData?.id?.shiftTitle}</Typography>
                              <Typography variant="subtitle2" sx={{ margin: 0 }}>
                                {userData?.officeInformation?.shiftData?.id?.startTime} - {userData?.officeInformation?.shiftData?.id?.endTime}
                              </Typography>
                              <Typography variant="body2" sx={{ margin: 0 }}>
                                {userData?.officeInformation?.shiftData?.id?.duration} hrs
                              </Typography>
                            </Box>
                          )
                        }
                      </Box>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer >
      <Popover open={isPopoverOpen} anchorEl={anchorEl} onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={(event) => handleOptionClick('Add Shift', event)}>
          <ListItemIcon>
            <CalendarToday fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Shift</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick('weekOff')}>
          <ListItemIcon>
            <FlightTakeoff fontSize="small" />
          </ListItemIcon>
          <ListItemText>Week Off</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick('leave')}>
          <ListItemIcon>
            <EventBusy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Leave</ListItemText>
        </MenuItem>
      </Popover>
      <Popover open={isSecondPopoverOpen} anchorEl={secondPopoverAnchorEl} onClose={() => handleSecondPopoverClose(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {createdShiftData.map((shift) => (
          <MenuItem key={shift._id} onClick={() => handleSecondPopoverClose(shift)}>
            <ListItemText>{shift.shiftTitle}</ListItemText>
          </MenuItem>
        ))}
      </Popover>
      {loading && <Loader />}
    </>
  );
};

export default ShiftTable;
