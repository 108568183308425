import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Button, Box, Typography, Select, MenuItem, InputAdornment } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

import Loader from '../../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../../Redux/selector';
import { salaryStructureSetup, getSalaryStructure } from '../../../../../utils/Services/subscriberConfigurationServices/salaryStructureServices';

function SalaryStructure() {
    const [entries, setEntries] = useState([{ description: '', value: '', type: 'Earning' }]);
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { subscriberId } = useParams();

    const fetchSalaryStructure = async () => {
        try {
            setLoading(true);
            const response = await getSalaryStructure(token, subscriberId);

            if (response && response.data.salaryEntries?.length > 0) {
                setEntries(response.data.salaryEntries);
            }
        } catch (error) {
            console.error('Error fetching salary structure:', error.response?.data?.message || error.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSalaryStructure();
    }, [token, subscriberId]);

    const handleAddRow = () => {
        setEntries([...entries, { description: '', value: '', type: '' }]);
    };

    const handleTypeChange = (index, value) => {
        const newEntries = [...entries];
        newEntries[index].type = value;
        newEntries[index].value = '';
        setEntries(newEntries);
    };

    const handleChange = (index, field, value) => {
        const newEntries = [...entries];
        newEntries[index][field] = value;
        setEntries(newEntries);
    };

    const handleDeleteRow = (index) => {
        if (entries.length > 1) {
            setEntries(entries.filter((_, i) => i !== index));
        }
    };

    const handleUpdate = async () => {

        for (const entry of entries) {
            if (!entry.description || !entry.value || !entry.type) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Missing Fields',
                    text: 'Please fill in all fields before submitting.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
                return;
            }
        }

        try {
            setLoading(true);

            await salaryStructureSetup(token, subscriberId, entries);

            Swal.fire({
                icon: 'success',
                title: 'Updated Successfully!',
                text: 'The salary structure has been updated.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } catch (error) {
            console.error('Error updating salary structure:', error.response?.data?.message || error.message);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to update salary structure',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
            fetchSalaryStructure();
        }
    };

    return (
        <span>
            <Container sx={{ padding: '20px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        Salary Structure Configuration
                    </Typography>
                    <Button variant="contained" startIcon={<Add />} onClick={handleAddRow}
                        sx={{
                            backgroundColor: '#1B4965',
                            color: 'white',
                            '&:hover': { backgroundColor: '#174d68' },
                        }}
                    >Add Row
                    </Button>
                </Box>

                <TableContainer component={Paper} elevation={3} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
                    <Table sx={{ width: '100%', tableLayout: 'fixed' }}>
                        <TableHead sx={{ backgroundColor: '#1B4965' }}>
                            <TableRow>
                                <TableCell style={{ width: '20%', color: 'white' }}>Type</TableCell>
                                <TableCell style={{ width: '40%', color: 'white' }}>Description</TableCell>
                                <TableCell style={{ width: '30%', color: 'white' }}>Value</TableCell>
                                <TableCell style={{ width: '10%', color: 'white' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entries.map((entry, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ width: '20%' }}>
                                        <Select fullWidth value={entry.type} onChange={(e) => handleTypeChange(index, e.target.value)}>
                                            <MenuItem value="Earning">Earning</MenuItem>
                                            <MenuItem value="Employer Contribution">Employer Contribution</MenuItem>
                                            <MenuItem value="Deduction">Deduction</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell style={{ width: '40%' }}>
                                        <TextField fullWidth variant="outlined" value={entry.description} onChange={(e) => handleChange(index, 'description', e.target.value)} />
                                    </TableCell>
                                    <TableCell style={{ width: '30%' }}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder={entry.type === "Employer Contribution" || entry.type === "Deduction" ? "Enter Amount" : "Enter Percentage"}
                                            value={entry.value}
                                            onChange={(e) => handleChange(index, "value", e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {entry.type === "Employer Contribution" || entry.type === "Deduction" ? "₹" : "%"}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <IconButton color="error" onClick={() => handleDeleteRow(index)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                    <Button variant="contained" onClick={handleUpdate}
                        sx={{
                            backgroundColor: '#174d68',
                            color: 'white',
                            '&:hover': { backgroundColor: '#1B4965' },
                        }}
                    >Update
                    </Button>
                </Box>
                {loading && <Loader />}
            </Container>
        </span>
    );
}

export default SalaryStructure;

const Container = styled.div`
  padding: 20px;
  height: 80vh;
  overflow-y: auto;
`;