import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import Swal from 'sweetalert2';
import { Button, Grid, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../../Redux/selector';
import { getuserDetailsByTabName, updateUser } from '../../../../utils/Services/userServices'

function BankDetails() {
  const [bankInformationDetails, setBankInformationDetails] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { userId } = useParams();
  const user = useSelector(selectUser);
  const tabName = "bankDetails"

  // To get bank details
  const fetchBankDetails = async () => {
    setLoading(true);
    try {
      const response = await getuserDetailsByTabName(token, tabName, userId);
      setBankInformationDetails(response?.data?.bankInformation)
    } catch (err) {
      console.error('Error fetching user bank details:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to fetch user bank details',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBankDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle to edit the fields
  const handleEditClick = async () => {
    if (isEditing) {
      await fetchBankDetails();
    }
    setIsEditing(prevEditing => {
      const newEditingState = !prevEditing;
      if (newEditingState) {
        Swal.fire({
          icon: 'info',
          title: 'Editing Enabled',
          text: 'Now you can edit the fields.',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      }
      return newEditingState;
    });
  };

  // Hanlde Update
  const handleUpdate = async () => {
    setLoading(true);
    try {
      const updatedData = { bankInformationDetails }
      await updateUser(token, userId, tabName, updatedData);
      Swal.fire({
        icon: 'success',
        title: 'Bank Details Updated',
        text: 'The bank details have been successfully updated.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'There was an error updating the bank details. Please try again.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
      console.error('Error updating bank details:', err);
    } finally {
      setLoading(false);
      setIsEditing(false);
      fetchBankDetails();
    }
  };

  return (
    <span>
      <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
        <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
          <Grid item>
            <Button variant="contained"
              sx={{
                backgroundColor: '#5A9BD5',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#5A9BD5',
                  opacity: 1
                }
              }}
              endIcon={<EditIcon />}
              onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
            </Button>
          </Grid>
          <Grid item>
            {isEditing && (
              <Button variant="outlined"
                sx={{
                  backgroundColor: '#1B4965',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#1B4965',
                    opacity: 1
                  }
                }}
                endIcon={<RestartAltIcon />}
                onClick={() => {
                  if (isEditing) {
                    handleUpdate();
                  }
                }}
              > Update
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField id="accountNumber" name="accountNumber" label="Bank Account Number" variant="outlined" fullWidth margin="dense"
              disabled={!isEditing}
              onChange={(e) => setBankInformationDetails({ ...bankInformationDetails, accountNumber: e.target.value })}
              value={bankInformationDetails?.accountNumber || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField id="accountType" name="accountType" label="Bank Account Type" variant="outlined" fullWidth margin="dense"
              disabled={!isEditing}
              onChange={(e) => setBankInformationDetails({ ...bankInformationDetails, accountType: e.target.value })}
              value={bankInformationDetails?.accountType || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField id="ifscCode" name="ifscCode" label="IFSC Code" variant="outlined" fullWidth margin="dense"
              disabled={!isEditing}
              onChange={(e) => setBankInformationDetails({ ...bankInformationDetails, ifscCode: e.target.value })}
              value={bankInformationDetails?.ifscCode || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField id="bankName" name="bankName" label="Bank Name" variant="outlined" fullWidth margin="dense"
              disabled={!isEditing}
              onChange={(e) => setBankInformationDetails({ ...bankInformationDetails, bankName: e.target.value })}
              value={bankInformationDetails?.bankName || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
        <TextField id="bankBranch" name="bankName" label="Bank Branch Name" variant="outlined" fullWidth margin="dense"
          disabled={!isEditing}
          onChange={(e) => setBankInformationDetails({ ...bankInformationDetails, bankBranch: e.target.value })}
          value={bankInformationDetails?.bankBranch || ''}
          sx={{
            '& .MuiInputLabel-root': { fontSize: '14px' },
            '& .MuiInputBase-root': { fontSize: '14px' },
            width: '100%'
          }}
        />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField id="pfNumber" name="pfNumber" label="PF Account Number" variant="outlined" fullWidth margin="dense"
              disabled={!(isEditing && user?.role === "Company-super-admin")}
              onChange={(e) => setBankInformationDetails({ ...bankInformationDetails, pfNumber: e.target.value })}
              value={bankInformationDetails?.pfNumber || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField id="uanNumber" name="uanNumber" label="UAN" variant="outlined" fullWidth margin="dense"
              disabled={!(isEditing && user?.role === "Company-super-admin")}
              onChange={(e) => setBankInformationDetails({ ...bankInformationDetails, uanNumber: e.target.value })}
              value={bankInformationDetails?.uanNumber || ''}
              sx={{
                '& .MuiInputLabel-root': { fontSize: '14px' },
                '& .MuiInputBase-root': { fontSize: '14px' },
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
      </Container>
      {(loading) && (
        <Loader />
      )}
    </span>
  )
}

export default BankDetails

const Container = styled.div`
    padding: 20px;
    height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #1B4965 #f0f0f0;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1B4965;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
`;
