import { useState } from 'react';
import { Tabs, Tab, Typography, Box, Paper } from '@mui/material';
import AllEmployeesAttendance from './Components/AllEmployeesAttendance';
import IndividualEmployeeAttendance from './Components/IndividualEmployeeAttendance';

const UserAttendance = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ minHeight: '100vh', padding: '5px' }}>
      <Paper elevation={3} sx={{ padding: '5px' }}>

        <Tabs value={value} onChange={handleChange} centered indicatorColor="primary" textColor="primary"
          sx={{
            marginBottom: '20px',
            '& .MuiTab-root': { fontSize: '16px', fontWeight: 'bold', textTransform: 'none' },
          }}
        >
          <Tab label="All Employees" />
          <Tab label="Individual Attendance" />
        </Tabs>

        {value === 0 && <AllEmployeesAttendance />}
        {value === 1 && <IndividualEmployeeAttendance />}
      </Paper>
    </Box>
  );
};

export default UserAttendance;
